@font-face {
  font-family: 'Gilroy';
  src: url('../assets/Font/Gilroy/Gilroy-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/Font/Gilroy/Gilroy-ExtraBold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/Font/Gilroy/Gilroy-Light.otf');
  font-weight: normal;
  font-style: italic;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
.cursor-pointer {
  cursor: pointer;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: none !important;
}
a {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  color: inherit;
  text-decoration: none !important;
}
* {
  box-sizing: border-box;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
.home_collegepass__Yzacw .upcoming-live h1 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  font-size: 1.5rem !important;
  color: #ffffff;
  text-transform: uppercase !important;
}
h1 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
h2 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  font-size: 1.5rem !important;
  color: #ffffff;
  text-transform: uppercase !important;
}
h3 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
h4 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
h5 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
h6 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
p {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
span {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
div {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
button {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
li {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
ol {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
ul {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
label {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
td {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
th {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
.black-color {
  color: #000000 !important;
}
.white-color {
  color: #ffffff !important;
}
.width-100 {
  width: 100%;
}
.border-bottom-none {
  border: none !important;
}
.border-top-none {
  border: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
.btn-primary:active:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.text-right {
  text-align: right;
}
.btn-primary:disabled {
  /* background-color: #ffffff8f !important; */
  background-color: #ffffff !important;
  color: #000000f2 !important;
  border: 1px solid #ffffff !important;
}
.horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-next {
  right: 0px;
  left: auto;
  height: 61% !important;
  top: 20%;
  background: #0000008c;
  border-radius: 0 !important;
  opacity: 0;
}
.horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-next:hover {
  right: 0px;
  left: auto;
  height: 61% !important;
  top: 23%;
  background: #0000008c;
  border-radius: 0 !important;
  opacity: 2;
}
.horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-prev {
  right: auto;
  left: 0px;
  height: 61% !important;
  top: 20%;
  background: #0000008c;
  border-radius: 0 !important;
  opacity: 0;
}
.horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-prev:hover {
  right: auto;
  left: 0px;
  height: 61% !important;
  top: 23%;
  background: #0000008c;
  border-radius: 0 !important;
  opacity: 2;
}
video {
  max-width: 100%;
}
.card_seriesEpisodesScroll___bKe1.col::-webkit-scrollbar {
  display: none;
}
.card_seriesEpisodesScroll___bKe1.col {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-link:focus,
.nav-link:hover {
  border: none !important;
  outline: none !important;
}
a#basic-nav-dropdown {
  border: none !important;
  outline: none !important;
}
.card_discriptionScroll__e0Bj6 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.visibility-hidden {
  visibility: hidden;
}
form.mandatory-form .form-control:focus {
  color: #212529;
  background-color: #ffffff !important;
  outline: 0 !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.form-control:focus {
  outline: 0 !important;
  border-color: rgb(227 204 128) !important;
  box-shadow: none !important;
}
iframe {
  max-width: 100%;
}
input.react-autosuggest__input {
  width: 100%;
}
.modal_regModalFormNew__KsOkx.col .form-control:disabled {
  background-color: #ffffff8a;
  opacity: 0.65;
}
.swiper-button-next {
  outline: none;
  border: none;
}
.swiper-button-next:active {
  outline: none;
  border: none;
}
.swiper-button-next:focus {
  outline: none;
  border: none;
}
.swiper-button-prev {
  outline: none;
  border: none;
}
.swiper-button-prev:active {
  outline: none;
  border: none;
}
.swiper-button-prev:focus {
  outline: none;
  border: none;
}
.device-modal .modal-dialog .modal-content {
  width: 400px !important;
  max-width: 100%;
  margin: auto;
  padding: 0;
  background: #222326;
  border: 1px solid #ffffff1f;
}
.device-modal {
  background: #000000c9;
}
.device-modal .modal-footer {
  border-top: 1px solid #ffffff1f;
  text-align: center;
  display: block;
  padding-right: 0px;
}
.device-modal .modal-footer button {
  border: none;
  cursor: pointer;
  background: #c83232;
  font-size: 16px;
  padding: 5px 20px;
}
/*.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: #000000!important;
  background-color: #ffffff!important;
  border-color: #000000!important;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #000000!important;
  background-color: #ffffff!important;
  border-color: #ffffff!important;
  box-shadow: none!important;
}*/
button.btn-close:hover {
  background-color: #ffffff00 !important;
  border: none !important;
}
.btn-primary:hover {
  /*color: #000000!important;
  background-color: #ffffff!important;*/
  border: none !important;
}
/* footer  */
.footer_footerCol__cW2yl ul {
  margin-left: 8%;
}

/*  Header  */
.after-login-mobile-menu {
  display: none;
}
.after-login-desktop-menu {
  display: block;
}
.fade.modal_loginModal__wNU0S.modal.show .PhoneInput {
  background: #ffffff !important;
  border: 1px solid #d6d6d6;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.header_ugHeader__VsIQA.col a#basic-nav-dropdown:hover .dropdown-menu {
  display: block !important;
}
.dropdown-menu[data-bs-popper] {
  top: 90% !important;
  left: -20% !important;
  margin-top: 0.125rem;
}
.welcome-drop-new {
  margin-right: -3% !important;
}
.welcome-drop-new .header_Login__Ysi34 {
  background: black !important;
  color: rgb(227 204 128) !important;
}
.dropdown-menu {
  padding: 0rem 0 !important;
}
.aftr-lgn-hdr .header_ugHeader__VsIQA .dropdown-toggle::after {
  right: 6%;
  content: '';
  position: absolute;
  top: 10% !important;
  height: 50%;
  width: 18px;
  border-radius: 0 5px 5px 0;
  border-top: 0;
  pointer-events: none;
  background-image: url(/download-drop.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.before-lgn-hdr .header_ugHeader__VsIQA .dropdown-toggle::after {
  right: 6%;
  content: '';
  position: absolute;
  top: 5% !important;
  height: 50%;
  width: 18px;
  border-radius: 0 5px 5px 0;
  border-top: 0;
  pointer-events: none;
  background-image: url(/download-drop.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.review_testimonialForDesktop__2E8gv.col .swiper-button-prev {
  left: -15px !important;
  right: auto;
}
.review_testimonialForDesktop__2E8gv.col .swiper-button-next {
  right: -15px !important;
  left: auto;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-black {
  margin: 0px 0px;
}
span.header_onMobileTogg__FaU_c {
  margin-right: 10px;
}

.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
span.hide-for-mobile {
  display: flex;
}
.only-for-mobile {
  display: none;
}
.navbar-nav a.header_app__jjsHt.nav-link {
  color: #a02928 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff !important;
  font-size: 12px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  /*opacity: 0.8;*/
}
.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #ffffff;
  text-align: left;
  list-style: none;
  background-color: #191c21 !important;
  background-clip: padding-box;
  border-radius: 0;
}
.dropdown-item {
  border-bottom: 1px solid #ffffff33 !important;
  color: #ffffff !important;
  font-size: 10px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  opacity: 0.8;
  padding: 20px 15px !important;
}
a.dropdown-item:after {
  content: ' ';
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #191c21;
}
.custom-dropdown-for-profile-icon .dropdown-menu[data-bs-popper] {
  left: -114px !important;
  top: 85% !important;
}
.diff-class {
  position: relative !important;
  top: -16px !important;
}
.custom-dropdown-for-profile-icon.nav-item.dropdown a.dropdown-item:after {
  content: ' ';
  position: absolute;
  right: 16%;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #191c21;
}
.dropdown-item:hover {
  background: none !important;
  /*border-left: 4px solid rgb(227 204 128);*/
  color: #e3cc80 !important;
  opacity: 10;
}
/* a.dropdown-item.header_nav_link__k0dYP.nav-link:hover {
  background: none !important;
  border-left: 4px solid rgb(227 204 128)!important;
}
a.dropdown-item.header_nav_link__k0dYP.nav-link {
  border-bottom: 1px solid #ffffff33 !important;
  color: #ffffff !important;
  font-size: 10px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  opacity: 0.8;
  padding: 20px 15px !important;
} */
.dropdown-item:focus {
  border-left: 3px solid !important;
  background: none !important;
}
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  border-left: 4px solid rgb(227 204 128) !important;
  background: none !important;
}
.dropdown-item:focus {
  border-left: 4px solid rgb(227 204 128) !important;
  background: none !important;
}
.afterLoginMenu .dropdown-menu[data-bs-popper] {
  left: -112px !important;
}

.me-auto.afterLoginMenu.navbar-nav .nav-item.show.dropdown .dropdown-menu.show {
  left: -110px !important;
  position: absolute !important;
}
.afterLoginMenu p {
  color: #ffffff;
  font-size: 10.5px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  opacity: 0.8;
  margin-bottom: 0;
  line-height: 1.7rem;
}

/* login modal */

button.kep-login-facebook.metro {
  margin: 5px auto;
  width: 100%;
  letter-spacing: 1.2px;
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block !important;
  font-size: 14px !important;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #4c69ba !important;
  border: none;
  text-align: center;
  padding: 15px 30px !important;
  border-radius: 3px !important;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex !important;
}
button.kep-login-facebook.metro i.fa.fa-facebook {
  margin-top: 3px;
}
.logn-modal-join-today.col {
  text-align: center;
}
.logn-modal-join-today.col button {
  font-size: 16px;
  padding: 12px 15px !important;
  border-radius: 50px;
  /*background: linear-gradient(120deg,rgba(255,74,158,0) 0%,#ff3190 100%)!important;*/
  background-color: #ee2f4c !important;
  border: 1px solid #ee2f4c !important;
  color: #ffffff;
  font-weight: 600;
  margin: 15px 0px 15px 0px;
  width: 100%;
  letter-spacing: 0.04rem;
}
.fade.modal_loginModal__1MQYl.modal.show .loginHolo img {
  width: 50px;
  max-width: 100%;
}
.modal_loginModal__wNU0S .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: 1.5rem 0.5rem 0.5rem 0.5rem;
  position: absolute;
  right: 0;
}
.modal_loginModal__wNU0S .modal-dialog {
  max-width: 450px;
  margin: 5rem auto !important;
}

/*  mandatory field */
form.mandatory-form .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 50px;
  width: 320px;
  border: 1px solid #323644;
  background-color: #ffffff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.fade.mandatory_userMandatoryForm__O_MbF .modal-content {
  margin: auto;
  padding: 35px 40px;
  background: #000000;
  border: 1px solid;
}
.form-select:focus {
  border-color: rgb(227 204 128) !important;
  outline: 0;
  box-shadow: none !important;
}
.css-qc6sy-singleValue {
  color: #ffffff !important;
}
.css-1pahdxg-control {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 5px;
  border-radius: 10px !important;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644 !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
  box-shadow: none !important;
}
.contact_selectInput__dr60A.contact_multiInput__DCmuD .css-1pahdxg-control {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 0px 5px;
  border-radius: 10px !important;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644 !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
  box-shadow: none !important;
}
.css-1s2u09g-control #react-select-5-listbox {
  background-color: #323644 !important;
}
.ib-cambridge-tutoring_interestIN__AwxQi.col#react-select-5-listbox,
.contact_interestIN__IbyKw.col#react-select-5-listbox {
  background-color: #323644 !important;
}
.ib-cambridge-tutoring_interestIN__AwxQi.col#react-select-5-listbox option:hover,
.contact_interestIN__IbyKw.col#react-select-5-listbox option:hover {
  background-color: #808080 !important;
}
.css-1s2u09g-control #react-select-5-listbox option:hover {
  background-color: #808080 !important;
}
.modal_regModalFormNew__KsOkx.col .css-1pahdxg-control {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 0px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644 !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
  box-shadow: none !important;
  min-height: 10px !important;
}
#react-select-3-listbox {
  /*background: #323644!important;*/
  color: #000000 !important;
}
#react-select-3-listbox p:hover {
  background: #323644 !important;
  color: #ffffff !important;
}

#react-select-5-listbox {
  /*background: #323644!important;*/
  color: #000000 !important;
}
#react-select-5-listbox p:hover {
  background: #323644 !important;
  color: #ffffff !important;
}
.fade.mandatory_userMandatoryForm__O_MbF .form-select {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 10px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.fade.mandatory_userMandatoryForm__O_MbF .modal-dialog {
  max-width: 800px !important;
}
.fade.mandatory_userMandatoryForm__O_MbF .react-autosuggest__container input.react-autosuggest__input {
  padding: 15px 10px !important;
}
.fade.mandatory_userMandatoryForm__O_MbF input {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 10px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644 !important;
}

input.react-autosuggest__input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  /*border: 1px solid #ced4da!important;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mandatory .modal-dialog {
  max-width: 900px !important;
  margin: 4% auto;
}
.mandatory .modal-content {
  width: 700px !important;
  max-width: 100%;
  margin: auto;
  /*background-color: #a7265a;*/
}
.reg-form-modal .modal-content {
  width: 800px !important;
  max-width: 100%;
  margin: auto;
  padding: 35px 40px;
  background: #0a0a0a;
  border: 1px solid #ffffff1f;
}
.reg-form-modal .sidemodal {
  /*background: #000000!important;*/
}
.reg-form-modal .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 7px !important;
  background: #928e9a00 url(/cross-icon.png) center/1.5em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: inherit;
}
.modal_loginModal__wNU0S .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 7px !important;
  background: #928e9a00 url(/cross_icon_new.png) center/1.1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: inherit;
}
.modal_loginModal__wNU0S .modal-content {
  padding: 0px 68px;
  background-color: rgba(0, 0, 0, 0.77);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}
.modal_titleModal__1MKhh.col h4 {
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.03rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  opacity: 0.9;
  padding: 5px 0px 5px 0px;
}
.modal_titleModal__1MKhh.col h6 {
  letter-spacing: 0.03rem;
  font-size: 18px;
  opacity: 0.8;
  color: #ffffff;
  margin-bottom: 0;
}
.lgn-link:hover {
  /*color: #e3cc80!important;*/
}
.modal_loginModal__wNU0S input::placeholder {
  color: #a1abb8;
}
.modal_loginModal__wNU0S input:-ms-input-placeholder {
  color: #a1abb8;
}
.modal_loginModal__wNU0S input::-ms-input-placeholder {
  color: #a1abb8;
}
p.modal_modalPrivacy__C0vsX a {
  color: #127fd1;
  font-weight: 600;
}
.reg-form-modal button {
  letter-spacing: 0.03rem;
  font-size: 22px;
  padding: 15px 25px;
  border-radius: 50px;
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #000000;
  font-weight: 600;
  margin: 15px 10px 15px 0px;
  width: 45%;
}
.reg-form-modal button:hover {
  border: 1px solid #ffffff !important;
  color: #000000;
}
.reg-form-modal button:active {
  background: #ffffff;
  border: 1px solid #ffffff !important;
  color: #000000;
}
.reg-form-modal button:focus {
  background: #ffffff;
  border: 1px solid #ffffff !important;
  color: #000000;
}
.reg-form-modal form.mandatory-form button:hover {
  background-color: #ffffff;
  border: 1px solid #ffffff !important;
  color: #000000;
}
.reg-form-modal input.react-autosuggest__input {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 20px !important;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  height: auto !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
}
.reg-form-modal .css-1s2u09g-control {
  background-color: #323644;
  border: none !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 5px 0px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644 !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
  box-shadow: none !important;
  min-height: 10px !important;
}
.MuiFormControl-root.MuiTextField-root.css-i44wyl {
  width: 100%;
}
.fade.mandatory_userMandatoryForm__O_MbF.modal.show .css-1s2u09g-control {
  background-color: #323644;
  border: none !important;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 5px 0px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644 !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
  box-shadow: none !important;
  min-height: 10px !important;
}
.reg-form-modal .css-g1d714-ValueContainer {
  padding: 5px 8px !important;
}
.reg-form-modal .css-319lph-ValueContainer {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 17px !important;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  height: auto !important;
  color: #ffffff;
  border: 1px solid #323644 !important;
}
.reg-form-modal input {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.tc-check {
  margin-left: 10px;
}
.reg-form-modal .tc-check input:focus {
  box-shadow: none;
}
.reg-form-modal .tc-check input {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 7px 7px;
  margin-top: 2px;
  border-radius: 2px;
  background: #323644;
  width: auto;
  color: #ffffff;
  border: 1px solid #323644;
  float: left;
  cursor: pointer;
}
.reg-form-modal .tc-check label {
  display: contents;
}
input.PhoneInputInput:focus {
  border: none;
  outline: none;
}
.reg-form-modal select {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.reg-form-modal .modal-title {
  margin-bottom: 5%;
  line-height: 1.5;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}
.reg-form-modal button.btn-close {
  position: absolute;
  left: 93% !important;
  top: 2.5% !important;
}
.reg-form-modal button.btn-close:hover {
  border: 1px solid #ffffff00 !important;
}
.mandatory .fade.modal.show {
  background: #000000d1;
}
.fade.mandatory.modal {
  background: #000000de;
}
/*.mandatory button.btn-close {
  display: none;
}*/
.mandatory button.btn-close {
  position: absolute;
  left: 97%;
  top: 1%;
}
.mandatory .modal-header {
  padding: 0px;
  border: none;
}
.welcome-drop-new {
  margin-right: 0;
}
.select-topics {
  padding: 25px 10px;
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-flow: wrap;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  overflow: auto;
}
.select-topics label {
  cursor: pointer;
  user-select: none;
  display: block;
}
.select-topics span {
  position: relative;
  border: 1px solid #000000;
  border-radius: 30px;
  transition: 0.4s;
  padding: 0 25px;
  height: 35px;
  color: #414141;
  display: flex;
  align-items: center;
  grid-column-gap: 7px;
  column-gap: 7px;
  font-size: 14px;
}
.select-topics span i {
  display: none;
}
.select-topics :checked + span i {
  display: block;
}
.select-topics input {
  pointer-events: none;
  display: none;
}
.select-topics :checked + span {
  border-color: #000000;
  color: #ffffff;
  background: #000000;
  /*background-image: linear-gradient(to right, #F595B2, #AFADDE);*/
}
.mandatory .sidemodal {
  /*background: #ffffff;*/
  /*margin: 50px -50px 50px 50px;*/
}

/* swiper */
.swiper-button-prev {
  font-size: 15px !important;
  color: #949aa4 !important;
  width: 3em !important;
  height: 3em !important;
  border-radius: 3em !important;
  /*background: #272c33 !important;*/
  position: absolute !important;
  display: flex;
}
.horizontalSlider_cardSliderForDesktop__Re9nS.col .swiper-button-next:after {
  color: #ffffff !important;
  font-size: 30px !important;
  font-weight: 600;
}
.horizontalSlider_cardSliderForDesktop__Re9nS.col .swiper-button-prev:after {
  color: #ffffff !important;
  font-size: 30px !important;
  font-weight: 600;
}
.swiper-button-next {
  font-size: 15px !important;
  color: #949aa4 !important;
  width: 3em !important;
  height: 3em !important;
  border-radius: 3em !important;
  /*background: #272c33 !important;*/
}
.swiper-button-prev:after {
  font-size: 25px !important;
}
.swiper-button-next:after {
  font-size: 25px !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px !important;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in;
  background: hsla(0, 0%, 100%, 0.4);
  bottom: 0 !important;
}
.swiper-pagination-bullet {
  height: 4px !important;
  width: 4px !important;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #fff;
  opacity: 1;
  transition: all 0.2s ease-in;
  height: 3px;
  width: 15px !important;
  border-radius: 15px;
  background-color: #fff !important;
}
.swiper-wrapper {
  padding-bottom: 25px;
}
.testimonial_testimonial__2wuI4.col .swiper-wrapper {
  padding-bottom: 0px;
}

/*  Faq  */

.faq_faq__1dOU5.row .accordion .accordion-item {
  border: 1px solid #3f3f40 !important;
  color: #ffffff !important;
  background-color: #191c21 !important;
}
.faq_faq__1dOU5.row .accordion .accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: #ffffff !important;
  background-color: #191c21 !important;
}
.faq_faq__1dOU5.row .accordion .accordion-button:after {
  background-image: none !important;
  content: '\f107' !important;
  float: right !important;
  border: none !important;
  font-family: 'FontAwesome' !important;
  font-size: 20px !important;
}
.accordion .accordion-button:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

/*  Landing Form  */

.form_landingForm__3okGA.row .accordion-button {
  background: none !important;
  font-size: 20px;
  line-height: 1.44;
  font-weight: 500;
  color: #fff;
  background: none;
  letter-spacing: 0.03em;
  margin-left: 5%;
}
.form_landingForm__3okGA.row .accordion-item {
  background: none !important;
}
.form_landingForm__3okGA.row .accordion-item {
  border-left: 2px solid #ffffff;
  margin: 0 10px;
}
.form_landingForm__3okGA.row h2.accordion-header {
  margin-top: -6.5%;
  padding: 15px 0;
}
.form_landingForm__3okGA.row .accordion-button:after {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url(https://collegepass.s3.ap-south-1.amazonaws.com/download_1.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

/*  Profile Section */
.my-profile .nav-link {
  color: #fff !important;
  font-size: 0.99rem;
  letter-spacing: 0.03rem;
  opacity: 0.9;
  border: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  width: 100%;
}
.my-profile .nav-tabs .nav-link.active {
  background: #e6a851 !important;
  color: #000000 !important;
}
.my-profile li.nav-item {
  border-right: 1px solid #ce9648;
  border-left: 1px solid #ce9648;
  border-top: 1px solid #ce9648;
  margin-bottom: -1px;
  width: 33.33%;
  max-width: 100%;
  text-align: center;
  background: #000;
  color: #000;
}
.my-profile .tab-content {
  background: #fff;
  padding: 30px;
  color: #000000;
}
.my-profile input.form-control {
  margin: 15px 0px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid#ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-profile .custom-select {
  margin: 15px 0px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid#ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-profile .custom-control-inline.col {
  margin: 0px 15px;
}
.my-profile .custom-input {
  margin-right: 5px;
}
.custom-d-flex {
  display: flex !important;
}

/*  payment  */
.payment-img {
  background-image: url(../public/banner-payment.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 45px 35px 60px 35px;
  background-size: cover;
  margin-top: 50px;
  background-color: #000000;
  background-image: linear-gradient(to right, #000 50%, transparent 58%), url(../public/banner-payment.jpeg);
  background-position: right top;
}
.border-bottom-none.reset-pass-modal.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -4.5rem 0.5rem -1.5rem auto !important;
}
.css-6j8wv5-Input input {
  color: #ffffff !important;
}
.css-6j8wv5-Input {
  color: #ffffff !important;
}
.fade.modal_loginModal__wNU0S.modal.show .btn-primary:disabled {
  background-color: #2371ff7a !important;
  color: #ffffff !important;
  border: none;
}
.app-rating_googleRating__SNKao.col-lg-4.col-md-6.col-sm-12.col-12 {
  padding-left: 5%;
}
.app-rating_appStoreSection__H6ILf.col-lg-4.col-md-6.col-sm-12.col-12 {
  padding-right: 3%;
}
.for-mobile-menu {
  display: none;
}
.for-desktop-menu {
  display: block;
}
.card_seriesModalBody__QZeXP.modal-body h5 {
  /* border-bottom: 2px solid rgb(227 204 128) !important;
  padding-bottom: 1.5%; */
  /* width: 6.7vw; */
  font-size: 1.3vw;
  color: rgb(227 204 128);
}
.card_seriesModalBody__QZeXP.modal-body p {
  font-size: 1vw;
  margin-bottom: 10px;
}
.card_seriesModalBody__QZeXP.modal-body .card_selectedEpisodeSeries__zXrnT.row h5 {
  border: none !important;
  width: 100%;
}
.card_discriptionScroll__e0Bj6::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

.card_discriptionScroll__e0Bj6::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 30px;
}

.card_discriptionScroll__e0Bj6::-webkit-scrollbar-thumb {
  background: rgb(227 204 128);
  border-radius: 15px;
}
#react-select-2-listbox {
  /*background: #5b6071!important;*/
  color: #000000 !important;
}
#react-select-2-listbox p:hover {
  background: #323644 !important;
  color: #ffffff !important;
}
.card_discriptionScroll__e0Bj6 h5 {
  margin: 20px 0px;
  /* border-bottom: 2px solid rgb(227 204 128) !important;
  padding-bottom: 1.5% !important; */
  font-size: 1.3vw !important;
  width: fit-content !important;
}
.live-stream-page-desk {
  display: block;
}
.live-stream-page-mobile {
  display: none;
}
.live-stream-page-desk {
  padding: 80px 0px;
}
.tutoring-form_lpdeskHederBannerLb__rLmLr.col .row {
  width: 610px;
}
.tutoring-form_lpdeskHederBannerLb__rLmLr.col .row .col {
  margin-left: 90px;
}
.tutoring-form_lpdeskHederBannerLb__rLmLr.col .row .col a {
  margin-bottom: 50px !important;
}

.google-form_lpdeskHederBannerLb__Zs2__.col .row {
  width: 610px;
}
.google-form_lpdeskHederBannerLb__Zs2__.col .row .col {
  margin-left: 90px;
}
.google-form_lpdeskHederBannerLb__Zs2__.col .row .col a {
  margin-bottom: 50px !important;
}
.mobile-logo-slider-lp {
  display: none;
}
.desk-logo-slider-lp {
  display: block;
}
.logoSlider_logoSliderheading__9XpR5 h2 {
  font-size: 22px !important;
}
.registration_testimonialModal__49fb4.undefined {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registration_testimonialModalcontainer__TN1TR {
  position: relative;
}
.registration_closeIcon__znciy {
  color: #fff;
  position: absolute;
  right: 80px !important;
  top: -20px !important;
  cursor: pointer;
}
.registration_FormSectionDl__x0fqo .PhoneInput {
  display: flex;
  align-items: center;
  background: #1a1c21 !important;
  padding: 0px 23px !important;
  border-radius: 4px !important;
  box-shadow: inset 0 0 0 1px #272c33 !important;
  /* border: 1px solid #272c33; */
}
.registration_FormSectionDl__x0fqo.col input.react-autosuggest__input {
  padding: 0.6rem 0.75rem !important;
}
.registration_FormSectionDl__x0fqo.col .PhoneInput .registration_FormSectionDl__x0fqo input {
  box-shadow: none !important;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  margin-top: 10px !important;
  height: 1rem !important;
  margin-left: auto;
}
.accordion-button:not(.collapsed)::after {
  margin-top: -10px !important;
}
.pass-eye {
  position: relative;
  display: inline-block; 
  width: 100%;
}
/* mobile  */

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .registration_swiperNew__beprs.col .swiper.swiper-initialized.swiper-horizontal.swiper-android .swiper-slide {
    height: 420px !important;
  }
  .registration_swiperNew__beprs.col .swiper.swiper-initialized.swiper-horizontal.swiper-ios .swiper-slide {
    margin: 0;
    height: 420px !important;
  }
  .landing-footer-bot .footer_ugBottomFooter__4Yfgr .footer_ugCopyRight__KazpP .footer_ugCopyRightR__nSQnd {
    padding-right: 0;
  }
  .landing-footer-bot.row .footer_ugBottomFooter__4Yfgr.col {
    padding: 25px 15px 20px 0px !important;
  }
  .landing-footer-bot.row .footer_ugBottomFooter__4Yfgr.col {
    text-align: center;
  }
  .accordion-button::after {
    margin-top: -5px !important;
  }
  .registration_closeIcon__znciy {
    color: #fff;
    position: absolute;
    right: 10px !important;
    top: 75px !important;
    cursor: pointer;
  }

  .registration_swiperNew__beprs.col .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events .swiper-slide {
    margin: 0;
    height: 410px !important;
  }
  /* bg dark for landing */
  .container-large {
    padding-top: 3rem !important;
  }
  .layout65_item-icon-wrapper {
    margin-right: 0.5rem;
  }
  .padding-global {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .list-wrapper,
  .list-wrapper.founder-circle {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .list1_component {
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    display: flex !important;
  }

  /* -------------------*/
  .registration_swiperNew__beprs .swiper-slide.swiper-slide-active {
    transform: scale(1.2) !important;
  }
  span.pass-eye svg {
    position: absolute;
    left: 58%;
  }
  .reset-pass-wrap span.pass-eye svg {
    position: absolute;
    left: 88%;
  }

  /* mobile  */

  /* @media only screen and (max-width: 480px) and (min-width: 320px) { */
  span.pass-eye svg {
    position: absolute;
    left: 87%;
  }
  .reset-pass-wrap span.pass-eye svg {
    position: absolute;
    left: 88%;
  }
  .otp-verify-modal .modal-content {
    padding: 48px 35px !important;
  }
  .modal_loginModal__wNU0S .modal-content {
    padding: 0px 45px !important;
  }
  .n-testimonial-ib_testimonialModalcontainer__1fMqs .n-testimonial-ib_closeIcon__nDsxO {
    right: 3% !important;
    top: 18% !important;
  }
  .scl-lgo-sldr-mbl.bg-white h2 {
    padding: 20px;
    font-size: 20px !important;
  }
  .n-testimonial-ib_AidenSection__MCe1O.col .custom-testimonial-padding {
    padding-left: 15px !important;
  }
  .scl-lgo-sldr-dsk {
    display: none !important;
  }
  .scl-lgo-sldr-mbl {
    display: block !important;
  }
  .home_collegepass__Yzacw .upcoming-live h1 {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 700 !important;
    line-height: 8vw !important;
    font-size: 5.2vw !important;
    letter-spacing: 0.04rem;
    color: #ffffff;
    text-transform: capitalize !important;
    margin-bottom: 10px !important;
    margin-top: -10px;
  }
  .registration_LdHeader__NeofT button.navbar-toggler {
    display: none;
  }
  .mobile-logo-slider-lp {
    display: block;
  }
  .desk-logo-slider-lp {
    display: none;
  }

  /* App rating landing page */
  .app-rating-lp_appStoreSection__zbPRZ {
    margin-top: 50px !important;
  }

  /*----------------------------*/

  .google-add_addSecondSec__iQ25z.col .col-lg-7.col-md-7.col-sm-12.col-12 {
    padding: 0;
  }
  .google-add_addCaroSecImg__h3lWU {
    text-align: center;
  }
  .google-add_addCaroSecImg__h3lWU {
    margin: auto;
    width: 188px !important;
    text-align: center;
    align-items: center;
  }
  .post_postDescText__FuoJz li {
    font-size: 18px !important;
    letter-spacing: 0px !important;
    word-spacing: 0 !important;
    line-height: 25px !important;
    margin-bottom: 0.78125rem;
    overflow-wrap: break-word;
  }
  .rel-post-spacing {
    margin-left: 5px;
  }
  p.card_time__ubBPF {
    position: absolute;
    top: -7%;
    left: 6%;
  }
  .google-form_ivyLandingPage__tT3nb {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  #google-contact .google-form_textInput__bkVZ8 {
    margin-bottom: 13px !important;
  }
  #google-contact .google-form_PhoneInput__letXx {
    margin-bottom: 13px !important;
  }
  #google-contact .google-form_react_autosuggest__input__xOjNG {
    margin-bottom: 13px !important;
  }
  #google-contact .fms {
    margin-bottom: 13px !important;
  }

  #google-contact .google-ivy-league_textInput__bkVZ8 {
    margin-bottom: 13px !important;
  }
  #google-contact .google-ivy-league_PhoneInput__letXx {
    margin-bottom: 13px !important;
  }
  #google-contact .google-ivy-league_react_autosuggest__input__xOjNG {
    margin-bottom: 13px !important;
  }
  #google-contact .fms {
    margin-bottom: 13px !important;
  }

  form#form {
    padding-top: 30px;
  }

  .live-stream-page-mobile {
    display: block;
  }
  .live-stream-page-mobile {
    padding: 45px 0px;
  }
  .live-stream-page-desk {
    display: none;
  }

  .review_testimonialForMobile__6Jg2n.col .swiper {
    padding: 40px 15px 25px 20px !important;
  }
  .scroll-slider_ugMobilrSilderMobileSection__Rg2jK.col .carousel-indicators {
    display: flex !important;
  }
  .smallTestimonial .carousel-indicators {
    display: flex !important;
  }
  .ug-admissions_ugTestimonialSilderScrollSection__HSKNs.col .carousel-indicators {
    display: flex !important;
  }
  .referral_testimonialCarousel__qABmV.col .carousel-indicators {
    display: flex !important;
  }
  .carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
    border-radius: 100%;
    margin-right: 5px;
    margin-left: 5px;
  }
  .google-form_gradeRedSec__M4URX {
    border-radius: 5px !important;
  }
  .landing-footer-top.row .footer_ugTopFooter__ChPgA.col {
    padding: 35px 0px 20px 20px !important;
  }
  .referral_admissionsSection__hx1_V li {
    font-size: 16px !important;
    letter-spacing: 0.05rem;
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 22px;
    position: relative;
    padding-left: 26px;
  }
  .referral_admissionsSection__hx1_V ul {
    list-style-type: none;
    padding-left: 0px;
  }
  .referral_admissionsSection__hx1_V li:before {
    position: absolute;
    top: 2px;
    left: -3px;
    font-family: FontAwesome;
    content: '\f067';
    color: #ffffff;
    background: #c83232;
    font-size: 12px;
    border-radius: 50px;
    padding: 0px 6px;
  }
  .referral_formSection__Dk1tg input.react-autosuggest__input {
    margin-top: 0 !important;
  }
  .referral_admissionDesc__P_37R {
    margin-bottom: 5px;
  }
  .referral_referralFrom__BNepG .referral_textSection__f_QVl {
    line-height: 30px;
    padding-right: 55px;
    text-align: left !important;
  }
  .css-yt9ioa-option {
    background-color: #ffffff !important;
  }
  /* referral 2.0 */
  .referral_testimonialCarousel__qABmV.col img {
    width: -webkit-fill-available !important;
  }
  /* referral 2.0 */
  button.modal_verifyOtp__j_Btn.btn.btn-primary {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 15px !important;
    border-radius: 50px !important;
    margin: 0 10px 0 0 !important;
    width: 100%;
    background: #e3cc80 !important;
    color: #000 !important;
    border: none;
  }
  .fade.get-link-cp.modal.show .modal-dialog {
    width: 860px !important;
    max-width: 100% !important;
    height: auto !important;
    padding: 20px 15px 60px 0px;
    text-align: center;
  }
  .fade.get-link-cp.modal.show .d-flex.pt-5.col {
    justify-content: center;
  }

  .reset-pass-wrap form {
    width: 100% !important;
  }
  .modal_loginModal__wNU0S .modal-header .btn-close {
    margin: 0.5rem 0.2rem 0.5rem -1.5rem;
  }
  a.account-change-pass-link {
    float: left;
  }
  p.text-right.account-change-pass-link {
    float: left;
  }
  nav.navbar.navbar-expand-lg.navbar-light.bg-black {
    margin: 0px 0px;
    display: none;
  }
  .registration_LdHeader__NeofT nav.navbar.navbar-expand-lg.navbar-light.bg-black {
    margin: 0px 0px;
    display: block;
  }
  #react-select-3-listbox div {
    background: #ffffff !important;
    color: #000000 !important;
  }
  #react-select-3-listbox {
    background: #5b6071 !important;
    color: #000000 !important;
  }
  #react-select-3-listbox p:hover {
    background: #323644 !important;
    color: #ffffff !important;
  }
  #react-select-2-listbox {
    background: #5b6071 !important;
    color: #000000 !important;
  }
  #react-select-2-listbox p:hover {
    background: #323644 !important;
    color: #ffffff !important;
  }

  #react-select-5-listbox {
    background: #5b6071 !important;
    color: #000000 !important;
  }
  #react-select-5-listbox p:hover {
    background: #323644 !important;
    color: #ffffff !important;
  }

  .review_reviewBannerText__2Ui5C.row img {
    width: 280px !important;
  }
  .card_seriesModalBody__QZeXP.modal-body h5 {
    /* border-bottom: 2px solid rgb(227 204 128) !important; */
    padding-bottom: 2.5%;
    width: fit-content !important;
    font-size: 4.5vw !important;
    line-height: 25px;
    /* margin-bottom: 4%; */
  }
  .card_seriesModalBody__QZeXP.modal-body p {
    margin-bottom: 10px;
  }
  .card_seriesModalBody__QZeXP.modal-body .card_selectedEpisodeSeries__zXrnT.row h5 {
    border: none !important;
    width: 100%;
  }
  .card_seriesModalBody__QZeXP.modal-body p {
    font-size: 3.9vw;
    letter-spacing: 0.05rem;
  }
  .card_seriesModalBody__QZeXP.modal-body h6 {
    padding: 5px 0px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05rem;
  }
  .card_seriesModalBody__QZeXP.modal-body p strong {
    font-size: 4.5vw;
    letter-spacing: 0.05rem;
  }
  .card_seriesModalBody__QZeXP.modal-body p span {
    font-size: 18px;
    display: block;
    letter-spacing: 0.05rem;
    padding: 10px 0px;
    color: #e3cc80;
  }
  .swiper-slide.swiper-slide-active {
    margin-right: 30px !important;
  }
  .for-mobile-menu {
    display: block;
  }
  .for-desktop-menu {
    display: none;
  }
  .offcanvas-end {
    width: 500px !important;
  }
  .offcanvas {
    background-color: #0f0f0f !important;
  }
  .offcanvas-body a {
    background: #1a1c1c;
    padding: 20px 15px !important;
    color: #ffffffc9 !important;
    letter-spacing: 0.04rem !important;
    border-radius: 15px !important;
    margin: 10px !important;
  }
  .offcanvas-body a#login .header_Login__Ysi34 {
    font-size: 20px;
    padding: 15px 15px;
    width: 60%;
    margin-left: -4%;
  }
  .offcanvas-body a#login {
    background: none !important;
  }
  .offcanvas-body a.welcome-drop-new.nav-link {
    background: none !important;
    color: rgb(227 204 128) !important;
  }
  .offcanvas-header .btn-close {
    box-sizing: content-box;
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url('/cross_icon_rev.png') center/1.5em auto no-repeat !important;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
  }
  .ib-cambridge-tutoring_interestIN__AwxQi.col .css-1s2u09g-control,
  .contact_interestIN__IbyKw.col .css-1s2u09g-control {
    font-size: 18px !important;
  }
  .google-form_interestIN__rsMF2.col .css-1s2u09g-control {
    font-size: 18px !important;
  }
  .upcoming-live h2 {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 700 !important;
    line-height: 8vw !important;
    font-size: 5.2vw !important;
    letter-spacing: 0.04rem;
    color: #ffffff;
    text-transform: capitalize !important;
    margin-bottom: 10px !important;
    margin-top: -10px;
  }
  .horizontalSlider_cardSliderForMobile__52wGi.col .swiper {
    padding: 0px 0px 0px 10px !important;
  }
  .series-modal .modal-content {
    /*height: 500vw;*/
    position: sticky !important;
  }
  .swiper-wrapper {
    padding-bottom: 0px;
  }
  .navbar {
    padding-bottom: 0rem !important;
  }
  /*.app-rating_playStoreSection__gA9LI.col-lg-4.col-md-6.col-sm-12.col-12 .d-inline-flex.justify-content-center.col {
    justify-content: flex-start!important;
  }
  .app-rating_googleRating__SNKao.col-lg-4.col-md-6.col-sm-12.col-12 .d-inline-flex.justify-content-center.col {
    justify-content: flex-start!important;
  }
  .app-rating_appStoreSection__H6ILf.col-lg-4.col-md-6.col-sm-12.col-12 .d-inline-flex.justify-content-center.col {
    justify-content: flex-start!important;
  }*/
  .series-modal .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -11rem 0.5rem 0.5rem auto !important;
  }
  .card_seriesEpisodesModalForMobile__sl5Ha.col .nav-tabs .nav-link.active {
    font-weight: 600;
    color: rgb(227 204 128);
    background: none;
    border-top: none !important;
    border-right: none;
    border-bottom: 2px solid rgb(227 204 128) !important;
    font-size: 20px;
    letter-spacing: 0.03rem;
    border-left: none;
  }
  .card_seriesEpisodesModalForMobile__sl5Ha.col .nav-tabs .nav-link {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03rem;
    color: #ffffff80;
  }
  .card_seriesEpisodesModalForMobile__sl5Ha.col ul.mb-3.nav.nav-tabs {
    justify-content: center;
    border: none;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 30px !important;
    display: none;
  }
  .review_testimonialForDesktop__2E8gv.col .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px !important;
    display: block;
  }
  .fade.series-modal.modal.show {
    top: 0%;
  }
  .series-modal .modal-dialog {
    max-width: 850px;
    margin: 15% auto !important;
  }
  /* logo slider */
  .logoSlider_logoSlider__4swIk.row {
    padding: 0% 3%;
  }
  .border-bottom-none.reset-pass-modal.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -3.5rem 0.1rem -0.5rem auto !important;
  }
  .reg-form-modal button.btn-close {
    position: absolute;
    left: 88.5% !important;
    top: 3% !important;
  }
  .header_ugHeader__VsIQA .dropdown-toggle::after {
    right: 10%;
    content: '';
    position: absolute;
    top: -10%;
    height: 50%;
    width: 18px;
    border-radius: 0 5px 5px 0;
    border-top: 0;
    pointer-events: none;
    background-image: url(/download-drop.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  .dropdown-menu[data-bs-popper] {
    top: 85% !important;
    left: 0;
    margin-top: 0.125rem;
  }
  a.dropdown-item:after {
    content: ' ';
    position: absolute;
    right: 33px;
    top: 25px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #191c21;
  }
  .series-modal .modal-header {
    padding: 0;
    margin-bottom: 0%;
  }
  .reg-form-modal button {
    width: 100%;
    font-size: 18px;
    padding: 13px 25px !important;
  }
  .reg-form-modal .css-319lph-ValueContainer {
    padding: 15px 15px !important;
  }
  .reg-form-modal input.react-autosuggest__input {
    padding: 15px 15px !important;
  }
  .reg-form-modal select {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 15px 15px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
    width: 100%;
    background: #323644;
    color: #ffffff;
    border: 1px solid #323644;
  }
  .reg-form-modal input {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 15px 15px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
    width: 100%;
    background: #323644;
    color: #ffffff;
    border: 1px solid #323644;
  }
  .reg-form-modal .modal-title {
    margin-bottom: 5%;
    margin-left: 5%;
    line-height: 1.5;
    color: #ffffff;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .reg-form-modal .modal-content {
    width: 800px !important;
    max-width: 100%;
    margin: auto;
    padding: 35px 0px;
    border-radius: 30px;
    background: #261e35;
  }
  .after-login-mobile-menu {
    display: block;
  }
  a#icon-dropdone-af-l {
    display: none;
  }
  .fade.mandatory_userMandatoryForm__O_MbF .form-select {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 15px 10px;
    border-radius: 10px;
    margin: 5px 10px 15px 0px;
    width: 100%;
    background: #323644;
    color: #ffffff;
    border: 1px solid #323644;
  }
  .fade.mandatory_userMandatoryForm__O_MbF .modal-content {
    margin: auto;
    padding: 30px 15px;
    border-radius: 30px;
    background: #261e35;
    border: 5px solid rgb(227 204 128);
  }
  .pricing-custom-drop .btn-group label {
    padding: 10px 30px !important;
  }
  .footer_footerCol__cW2yl ul {
    margin-left: 0%;
  }
  .swiper-button-prev {
    display: none !important;
  }
  .mandatory button.btn-close {
    position: absolute;
    left: 92%;
    top: 1%;
  }
  .navbar-toggler {
    padding: 0.25rem 0rem !important;
  }
  .swiper-button-next {
    display: none !important;
  }
  .form_landingForm__3okGA.row h2.accordion-header {
    margin-top: -15%;
    padding: 10px 0;
  }
  .custom-d-flex {
    display: block !important;
  }

  .my-profile .nav-link {
    font-size: 8.5px;
  }
  .payment-img {
    background-image: url(../public/banner-payment.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 45px 35px 60px 35px;
    background-size: cover;
    margin-top: 50px;
    background-color: #000000;
    background-image: linear-gradient(to right, #000 50%, transparent 80%), url(../public/banner-payment.jpeg);
    background-position: right top;
  }
  .mandatory .sidemodal {
    /*background: #ffffff;*/
    margin: 5px;
  }
  .curriculam_currBox__z9fUw {
    flex: auto !important;
  }
}

/* Pricing */

.pricing-custom-drop .btn-group {
  margin-top: 18px;
  margin-left: 0px;
  margin-bottom: 30px;
  /*background: #ffffff;*/
  border-radius: 50px;
}
.pricing-custom-drop .btn-group label {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  letter-spacing: 0.03rem;
  font-size: 16px;
  padding: 10px 60px;
  background: #1a222d;
  font-weight: 600;
  width: 100%;
}
.btn-outline-success {
  color: none !important;
  border-color: none;
}
.pricing-custom-drop label.btn.btn-outline-success {
  border-radius: 4px !important;
  color: #ffffff66;
  margin-left: 10px !important;
}
.pricing-custom-drop label.btn.btn-outline-danger {
  border-radius: 4px !important;
  color: #ffffff66;
  margin-right: 10px;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: 1px !important;
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #ffffff !important;
  background-color: #1a222d !important;
  border-top: 7px solid rgb(227 204 128) !important;
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #ffffff !important;
  background-color: #1a222d !important;
  border-top: 7px solid rgb(227 204 128) !important;
}
.btn-outline-danger:hover {
  background-color: #ffffff !important;
}
/*.btn-outline-success:hover {
  background-color: #ffffff!important;
}
span.pricing-custom-drop .btn-outline-success:hover {
  background-color: #1a222d!important;
}*/
span.pricing-custom-drop .btn-outline-danger:hover {
  background-color: #1a222d !important;
}

/* new faq pricing */
.pricing_priceFaq__145c4.col .accordion-button:not(.collapsed) {
  background-color: #e7f1ff0f;
  padding: 20px;
}

.newModal .modal-dialog {
  max-width: 575px !important;
  margin: 6.75rem auto;
}
.bg-img-modal .modal-dialog {
  max-width: 795px;
  margin: 1.75rem auto;
}
.top-img-modal .modal-dialog {
  max-width: 600px;
  margin: 1.75rem auto;
}

/* collegePass Plus Modal */
.modal-plus .modal-dialog {
  max-width: 940px;
  margin: 40px auto;
  background-color: #000000;
}
.modal-plus {
  background: #000000e0;
}

.upgrade-class .modal-dialog {
  max-width: 1200px;
  margin: 3rem auto;
}

/* Video card slider "*/

.video-card.row .card_cardCenterText__lMt8W.row {
  padding-top: 16% !important;
}
.video-slider-new.container-fluid .swiper-button-prev {
  left: 0px;
  top: 45%;
}
.video-slider-new.container-fluid .swiper-button-next {
  right: 0px;
  top: 45%;
}
/* FOR Autocomplete */
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 300px;
  /*height: 35px;*/
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 50px;
  width: 320px;
  border: 1px solid #323644;
  background-color: #323644;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #dcdcdc;
}
.footer {
  margin: 9px 20px;
  font-size: 12px;
  color: #777;
}
.custom-dropdown-for-profile-icon.nav-item.dropdown {
  width: 8%;
  margin-left: -30px;
}
input.PhoneInputInput::placeholder {
  color: #6c757d;
}
input.react-autosuggest__input::placeholder {
  color: #6c757d;
}
.contact_selectInput__dr60A.contact_multiInput__DCmuD .css-1s2u09g-control {
  padding: 0px 5px !important;
}
/* mobile */

@media only screen and (max-width: 600px) {
  .custom_martinSection__4vUkM.col img {
    width: 200px !important;
  }
  span.header_onMobileTogg__FaU_c img {
    width: 40px !important;
  }
  .pricing-custom-drop .btn-group {
    margin-left: 0px;
  }
  .navbar-toggler {
    background-color: #000000 !important;
  }
  /*nav.navbar.navbar-expand-lg.navbar-light.bg-black {
  padding-bottom: 10%;
}*/
  div#basic-navbar-nav {
    padding-bottom: 5%;
  }
  /*button.navbar-toggler.collapsed {
  margin-right: -10%;
}*/
  .footer_footerCol__cW2yl.col-lg-3.col-md-3.col-sm-12.col-12 ul {
    padding-left: 1rem !important;
  }
  .header_beforeLoginHeader__vCO_M.row nav.navbar.navbar-expand-lg.navbar-light.bg-black a.navbar-brand {
    margin-right: -2rem !important;
  }
  .only-for-mobile {
    display: block;
  }
  span.hide-for-mobile {
    display: none;
  }
  .video_eventVideoSlider__Xg4qS.col {
    padding-top: 0%;
  }

  input.react-autosuggest__input {
    width: 100%;
    /* margin-top: 5px; */
  }
  form.mandatory-form select.form-select {
    margin: 15px 0px;
  }

  /* custom menu */

  .menu-icon .navicon:before {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
    top: 5px;
  }
  .menu-icon .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
    top: -5px;
  }
  .menu-icon .navicon {
    background: #fff !important;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url('/menu_icon_final.png') !important;
    margin-right: 5px;
  }
}
/* OTP */
.fade.modal_loginModal__wNU0S.modal.show .PhoneInput input.PhoneInputInput {
  border: none !important;
}
.opt-fields-custom-lib.col .opt-fields-custom input {
  border: 1px solid #000000 !important;
  background: #e8f0fe !important;
}
.fade.modal_loginModal__wNU0S.modal.show .opt-fields-custom-lib.col .opt-fields-custom input {
  margin-left: 10px;
}
button.modal_verifyOtp__j_Btn.btn.btn-primary {
  border: none;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 50px !important;
  margin: 0px 10px 0px 0px !important;
  width: 100%;
  background: rgb(227 204 128);
  color: #000000 !important;
  border: none;
  /*border: 1px solid rgb(227 204 128)!important;*/
}
button.modal_verifyOtp__j_Btn.btn.btn-primary:hover {
  background: rgb(227 204 128);
  color: #000000 !important;
  border: none !important;
  /*border: 1px solid rgb(227 204 128)!important;*/
}

/* otp modal new */
.otp-verify-modal .modal-content {
  padding: 48px 68px;
  background-color: rgba(0, 0, 0, 0.77);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}
.otp-verify-modal .modal-dialog {
  max-width: 450px;
}
.otp-verify-modal {
  background: #000000ed;
  background-image: url('/login-bg.png');
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: cover;
  z-index: 0;
}
.otp-verify-modal::before {
  background-color: rgb(0 0 0 / 57%);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  z-index: -1;
  width: 100%;
}
.otp-verify-modal h4 {
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 23px;
  opacity: 0.9;
  line-height: 25px;
  margin-bottom: 0px;
}
.otp-verify-modal p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04rem;
  color: #ffffffbf;
  margin-bottom: 0px;
}
.otp-verify-modal input {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 13px 15px;
  border-radius: 5px;
  margin: 0px 10px 0px 0px;
  width: 100% !important;
  background: #0000004a !important;
  color: #ffffffbf !important;
  border: 1px solid #ffffff1a !important;
}
.otp-verify-modal button.btn.btn-primary {
  font-size: 18px;
  padding: 10px 15px !important;
  border-radius: 5px;
  background: #0071e3;
  border: 1px solid #0071e3;
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
  width: 100%;
  letter-spacing: 0.04rem;
}
.otp-verify-modal a {
  font-size: 14px;
  color: rgb(120 187 255);
  letter-spacing: 0.04rem;
  font-weight: 400;
  opacity: 10 !important;
  /* margin-left: 1%; */
}
/* -----------------------  */
.PhoneInput {
  display: flex;
  align-items: center;
  background: #323644 !important;
  padding: 0px 23px !important;
  border-radius: 10px !important;
}
.contact_contactDetailsNew__zYfCQ.col .PhoneInput {
  display: flex;
  align-items: center;
  background: #323644 !important;
  padding: 0px 15px !important;
  border-radius: 10px !important;
}
.mandatory_userMendSec__dRAjr.row .PhoneInput {
  display: flex;
  align-items: center;
  background: #323644 !important;
  padding: 0px 15px !important;
  border-radius: 10px !important;
}
.contact_contactDetailsNew__zYfCQ.col .PhoneInput input:focus {
  border: none !important;
  outline: none !important;
}
.otp-verify-modal button.btn.btn-primary:hover {
  font-size: 18px;
  padding: 10px 15px !important;
  border-radius: 5px;
  background: #0071e3;
  border: 1px solid #0071e3 !important;
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
  width: 100%;
  letter-spacing: 0.04rem;
}
.otp-verify-modal button.btn.btn-primary:focus {
  font-size: 18px;
  padding: 10px 15px !important;
  border-radius: 5px;
  background: #0071e3;
  border: 1px solid #0071e3;
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
  width: 100%;
  letter-spacing: 0.04rem;
}
.otp-verify-modal button.btn.btn-primary:active {
  font-size: 18px;
  padding: 10px 15px !important;
  border-radius: 5px;
  background: #0071e3;
  border: 1px solid #0071e3;
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
  width: 100%;
  letter-spacing: 0.04rem;
}

/*  Series  */
.fade.series-modal.modal.show {
  padding-left: 0 !important;
}
.fade.series-modal.modal.show {
  background: #000000;
}
.styles-module_item-provider__YgMwz {
  overflow: inherit !important;
}
.series-modal .modal-dialog {
  max-width: 850px;
}
i.fa.fa-play {
  background: none;
}
.series-modal .modal-header {
  padding: 0;
}
.series-modal .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -27rem 0.5rem 0.5rem auto;
  position: absolute;
  right: 0;
  /*background-image: "url('cross.png')";*/
  background: black url(/cross.png) center/1em auto no-repeat;
}
.series-modal .modal-content {
  background-color: #181818;
  color: #ffffff;
  box-shadow: 0 4px 8px 0 rgb(123 119 119 / 20%), 0 6px 20px 0 rgb(93 90 90 / 19%);
}
.series_cardSliderForDesktop__UajUK.col
  .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.series_swiperHeight__QVT7_
  .swiper-button-prev {
  left: 0 !important;
  top: 32% !important;
}
.series_cardSliderForDesktop__UajUK.col
  .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.series_swiperHeight__QVT7_
  .swiper-button-next {
  top: 32% !important;
}
.swiper {
  padding: 40px 15px 0px 20px !important;
  /* overflow: visible!important; */
}
.registration_swiperNew__beprs .swiper {
  padding: 90px 35px 30px 35px !important;
  /* overflow: visible!important; */
}
.video-slider-new .swiper {
  padding: 0px !important;
}
/* live Blinking Dot */
.ring-container {
  position: relative;
  margin-right: 3px;
}
.circle {
  left: -3px;
  width: 8px;
  height: 8px;
  background-color: #8f0606;
  border-radius: 50%;
  position: absolute;
  top: 3px;
}

.ringring {
  margin-left: -7px;
  border: 3px solid #8f0606;
  -webkit-border-radius: 30px;
  height: 15px;
  width: 15px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
/****************************/
/* Video Card css V4 (series) */
.video-card.row .card_showImage__v0xTw.col {
  margin-right: 7px;
  margin-left: 7px;
}
/*************/
/* Register Form Loading Spiner */
.sp-loadbar {
  width: 50px;
  height: 18px;
  border: 1px #000 solid;
  border-radius: 4px;
  background: linear-gradient(
    -60deg,
    transparent 0%,
    transparent 50%,
    #000 50%,
    #000 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 30px;
  background-position: 0px 0px;
  -webkit-animation: spLoadBar 0.8s infinite linear;
  animation: spLoadBar 0.8s infinite linear;
}
@-webkit-keyframes spLoadBar {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -20px 0px;
  }
}
@keyframes spLoadBar {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -20px 0px;
  }
}
.sp {
  width: 100px;
  height: 25px;
  clear: both;
  float: right;
  margin-top: 5px;
}
/**************************/

@media (min-width: 768px) and (max-width: 1024px) {
  .card_cardCenterSeriesNew__HGmvp.row {
    bottom: 2%;
    position: absolute;
    left: 7%;
  }
  .aboutbanner_textSetion__2QtU0.col-lg-5.col-md-5.col-sm-12.col-12 {
    margin-top: 0;
  }
  .aboutbanner_AboutBanner__c_2sW .aboutbanner_textSetion__2QtU0.col-lg-5.col-md-5.col-sm-12.col-12 h2 {
    font-weight: 700 !important;
    line-height: 1.25 !important;
    font-size: 1.6rem !important;
    color: #fff;
  }
  .aboutbanner_AboutBanner__c_2sW p {
    color: #ffffff;
    opacity: 0.9;
    letter-spacing: 0.04em;
    line-height: 1.4em;
    font-size: 16px;
    margin-bottom: 5px !important;
    margin-top: -15px;
  }
  .footer_footerCol__cW2yl ul {
    margin-left: 0%;
  }
  span.header_onMobileTogg__FaU_c span {
    overflow: inherit !important;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url('/download.png') !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
}
.card_cardBottomIcon__fe_2L.row i.fa.fa-plus {
  padding: 8px 10px;
  border-radius: 100%;
}
.card_cardBottomIcon__fe_2L.row i.fa.fa-thumbs-o-up {
  padding: 8px 9px;
  border-radius: 100%;
}
.card_cardBottomIcon__fe_2L.row i.fa.fa-thumbs-o-down {
  border-radius: 100%;
  padding: 8px 9px;
}
.card_cardBottomIcon__fe_2L.row i.fa.fa-heart-o {
  padding: 8px 8px;
  border-radius: 100%;
}
.likes-icons {
  padding: 0px 5px;
  /* border-radius: 30px; */
  /* width: 45%; */
  /* text-align: center; */
  /* top: 61%; */
  position: absolute;
  right: -3%;
}
i.fa.fa-thumbs-o-up.like-hover:hover .likes-icons {
  display: flex !important;
}
/*
.fade.mobile-app-modal.modal.show {
  display: none!important;
}
.fade.modal-backdrop.show {
  display: none!important;
}
*/

@media only screen and (max-width: 1080px) and (min-width: 453px) {
  .ring-container {
    position: relative;
    margin-right: 3px;
    margin-top: -2px;
  }
  .likes-icons {
    padding: 2px 3px;
    border-radius: 30px;
    width: 50%;
    text-align: center;
    top: 20%;
    position: absolute;
    right: -3%;
  }
  .swiper-button-prev {
    width: 2.5em !important;
    height: 2.5em !important;
  }
  .swiper-button-next {
    width: 2.5em !important;
    height: 2.5em !important;
  }
}
@media only screen and (max-width: 920px) and (min-width: 453px) {
  .ring-container {
    position: relative;
    margin-right: 3px;
    margin-top: -2px;
  }
  .likes-icons {
    padding: 2px 3px;
    border-radius: 30px;
    width: 60%;
    text-align: center;
    top: 16%;
    position: absolute;
    right: -3%;
  }
  .swiper-button-prev {
    width: 2.5em !important;
    height: 2.5em !important;
  }
  .swiper-button-next {
    width: 2.5em !important;
    height: 2.5em !important;
  }
}

/* Ug New Landing Page  */

.smallTestimonial .carousel-control-prev {
  left: 0px;
}
.smallTestimonial .carousel-control-next {
  right: 0px;
}
.smallTestimonial .carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2% !important;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
.space-for-card {
  margin: 0px 5px !important;
}
.my-class .card_cardImage__V3Atn {
  width: 100%;
  border-radius: 1rem;
  /*opacity: 0.4;*/
  padding: 0px 10px !important;
}
.scroll-slider_ugMobilrSilderMobileSection__Rg2jK.col .carousel-indicators {
  display: none;
}
.scroll-slider_ugMobilrSilderMobileSection__Rg2jK.col .carousel-control-prev {
  left: -25px;
  display: none;
}
.scroll-slider_ugMobilrSilderMobileSection__Rg2jK.col .carousel-control-next {
  right: -25px;
  display: none;
}
.header_ugHeader__VsIQA div#basic-navbar-nav a {
  font-size: 13px;
}
.nav-item.dropdown {
  padding-right: 2rem;
}
.app-rating_ugTextTestimonial___a81k .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: -12rem;
  margin-left: 15%;
  list-style: none;
}
.ug-admissions_ugAppSection__16_HT a.carousel-control-prev {
  visibility: hidden;
}
.ug-admissions_ugAppSection__16_HT.col .carousel-control-next {
  visibility: hidden;
}
.smallTestimonial .carousel-indicators {
  display: none;
}
.app-rating_ugTextTestimonial___a81k a.carousel-control-prev {
  display: none;
}
.app-rating_ugTextTestimonial___a81k a.carousel-control-next {
  display: none;
}
.app-rating_ugTextTestimonial___a81k.col .carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
  background-color: #ffffffb0;
}
.contact_contactDetailsNew__zYfCQ.col button.accordion-button.collapsed {
  background: #10101000;
  color: #cfcfcf;
  letter-spacing: 0.05rem;
  font-size: 25px;
  margin-bottom: 1rem;
}
.ib-cambridge-tutoring_contactDetailsNew__FO9U_.col button.accordion-button.collapsed {
  background: #10101000;
  color: #cfcfcf;
  letter-spacing: 0.05rem;
  font-size: 25px;
  margin-bottom: 1rem;
}
.ib-cambridge-tutoring_interestIN__AwxQi.col .css-1s2u09g-control,
.contact_interestIN__IbyKw.col .css-1s2u09g-control {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 5px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.google-form_interestIN__rsMF2.col .css-1s2u09g-control {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 10px 5px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.contact_requiredCss__UgH9W .css-1s2u09g-control {
  border: 1px solid #ff3031 !important;
  background: #fff1f4 !important;
}
.ib-cambridge-tutoring_interestIN__AwxQi.col .css-1s2u09g-control::selection,
.contact_interestIN__IbyKw.col .css-1s2u09g-control::selection {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #e8f0fe;
  color: #ffffff;
  border: 1px solid #323644;
}
.google-form_interestIN__rsMF2.col .css-1s2u09g-control::selection {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #e8f0fe;
  color: #ffffff;
  border: 1px solid #323644;
}
.contact_contactDetailsNew__zYfCQ.col .accordion-item {
  background-color: #10101000;
}
.ib-cambridge-tutoring_contactDetailsNew__FO9U_.col .accordion-item {
  background-color: #10101000;
}
.contact_contactDetailsNew__zYfCQ.col .accordion-button:not(.collapsed) {
  background: #10101000;
  color: #cfcfcf;
  box-shadow: none;
  letter-spacing: 0.05rem;
  font-size: 25px;
  margin-bottom: 1rem;
}
.ib-cambridge-tutoring_contactDetailsNew__FO9U_.col .accordion-button:not(.collapsed) {
  background: #10101000;
  color: #cfcfcf;
  box-shadow: none;
  letter-spacing: 0.05rem;
  font-size: 25px;
  margin-bottom: 1rem;
}
.contact_contactDetailsNew__zYfCQ.col input {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.ib-cambridge-tutoring_contactDetailsNew__FO9U_.col input {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 15px 15px !important;
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  width: 100%;
  background: #323644;
  color: #ffffff;
  border: 1px solid #323644;
}
.contact_contactDetailsNew__zYfCQ.col .special-label {
  display: none;
}
.modal_regModalFormNew__KsOkx.col .special-label {
  display: none;
}
.fade.mandatory_userMandatoryForm__O_MbF.modal.show .special-label {
  display: none;
}
.contact_contactDetailsNew__zYfCQ.col input#formBasicEmail {
  width: 100%;
}
.contact_contactDetailsNew__zYfCQ.col .accordion-body {
  padding: 0rem;
}
.ib-cambridge-tutoring_contactDetailsNew__FO9U_.col .accordion-body {
  padding: 0rem;
}
.contact_contactDetailsNew__zYfCQ.col .accordion {
  width: 100%;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}
.accordion-button::after {
  background-image: url(/download-drop.png) !important;
}
.ug-admissions_ugTestimonialSilderScrollSection__HSKNs.col a.carousel-control-prev {
  display: none;
}
.ug-admissions_ugTestimonialSilderScrollSection__HSKNs.col a.carousel-control-next {
  display: none;
}
.ug-admissions_ugTestimonialSilderScrollSection__HSKNs.col .carousel-indicators {
  display: none;
}
.otp-verify-modal a:hover {
  color: rgb(227 204 128);
}
/* New testimonial css */

.n-testimonial_testimonialModal__pp8Ko.n-testimonial_fadeIn__hUbzp {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-testimonial_testimonialModalcontainer__KgnBs {
  position: relative;
}
.n-testimonial_closeIcon__Q4POF {
  color: #fff;
  position: absolute;
  right: 80px !important;
  top: -20px !important;
  cursor: pointer;
}
.n-testimonial_testimonialModalbg__4dq0s.n-testimonial_fadeIn__hUbzp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Testimonial Ivy */
.n-testimonial-ivy_testimonialModal__rjOY7.n-testimonial-ivy_fadeIn__tbgCR {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-testimonial-ivy_testimonialModalcontainer__mjGIZ {
  position: relative;
}
.n-testimonial-ivy_closeIcon__8IoiM {
  color: #fff;
  position: absolute;
  right: 7.5% !important;
  top: -20px !important;
  cursor: pointer;
}
.n-testimonial-ivy_testimonialModalbg__BbzjE.n-testimonial-ivy_fadeIn__tbgCR {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Testimonial MS */
.n-testimonial-ms-admissions_testimonialModal__c9FyA.n-testimonial-ms-admissions_fadeIn__f1gzq {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-testimonial-ms-admissions_testimonialModalcontainer___4Q_k {
  position: relative;
}
.n-testimonial-ms-admissions_closeIcon__PCfZY {
  color: #fff;
  position: absolute;
  right: 80px !important;
  top: -20px !important;
  cursor: pointer;
}
.n-testimonial-ms-admissions_testimonialModalbg__nAPIj.n-testimonial-ms-admissions_fadeIn__f1gzq {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Testimonial SAT */
.n-testimonial-sat_testimonialModal__o9jjT.n-testimonial-sat_fadeIn__T6iff {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-testimonial-sat_testimonialModalcontainer__YCXLK {
  position: relative;
}
.n-testimonial-sat_closeIcon__AbcQy {
  color: #fff;
  position: absolute;
  right: 80px !important;
  top: -20px !important;
  cursor: pointer;
}
.n-testimonial-sat_testimonialModalbg__kjVjL.n-testimonial-sat_fadeIn__T6iff {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Testimonial IB */
.n-testimonial-ib_testimonialModal__rRXNy.n-testimonial-ib_fadeIn__AizeW {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.n-testimonial-ib_testimonialModalcontainer__1fMqs {
  position: relative;
}
.n-testimonial-ib_closeIcon__nDsxO {
  color: #fff;
  position: absolute;
  right: 80px !important;
  top: -20px !important;
  cursor: pointer;
}
.n-testimonial-ib_testimonialModalbg__9Ic01.n-testimonial-ib_fadeIn__AizeW {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}
small.text-muted.form-text {
  color: #ff3031 !important;
  letter-spacing: 0.04rem !important;
}

/* Alert Modal Css */
.alert-modal-success .modal-dialog {
  max-width: 900px !important;
}
.alert-modal-success .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.alert-modal-success .modal-content {
  border-left: 12px solid #4ac860;
  border-radius: 10px;
}

.fade.modal_alertModalError__9yhMB.modal.show .modal-dialog {
  max-width: 900px !important;
}
.fade.modal_alertModalError__9yhMB.modal.show .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.fade.modal_alertModalError__9yhMB.modal.show .modal-content {
  border-left: 12px solid #fb6163;
  border-radius: 10px;
}

/*  get link in whatsapp  */

.get-link-cp .modal-dialog {
  width: 860px !important;
  max-width: 900px !important;
  height: 450px !important;
  background-color: rgb(255, 255, 255);
  border-radius: 50px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
  color: rgb(36, 38, 40);
  padding: 20px 55px 60px 60px;
  display: flex;
}
.get-link-cp .modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.get-link-cp .modal-content {
  border: none;
}
.get-link-cp h1 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 39px;
  white-space: pre-line;
  margin-top: 4px;
}
.get-link-cp p {
  opacity: 0.9;
  font-size: 16px;
  letter-spacing: 0.27px;
  margin-top: 15px;
  display: inline-block;
  margin-left: 2px;
}
.get-link-cp .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url('/cross-icon.png') center/2.1em auto no-repeat !important;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.get-link-cp .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -4.5rem 1.5rem -0.5rem auto;
}
a#logout-mob {
  color: rgb(227 204 128) !important;
}
a.welcome-drop-new.nav-link {
  margin-right: 0% !important;
}
span.header-icon {
  position: relative !important;
  right: 0 !important;
  top: -7px !important;
}
a.button.disable.dropdown-item {
  pointer-events: none;
}
/* .reset-pass-wrap form {
  max-width: 100%;
  width: 50%;
  margin: auto;
  padding-bottom: 50px;
} */
.modal_loginModal__wNU0S:before {
  background-color: rgba(0, 0, 0, 0.57);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  z-index: -1;
  width: 100%;
}
.get-link-cp .modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.get-link-cp .modal-content {
  border: none;
}
.get-link-cp h1 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 39px;
  white-space: pre-line;
  margin-top: 4px;
}
.get-link-cp p {
  opacity: 0.9;
  font-size: 16px;
  letter-spacing: 0.27px;
  margin-top: 15px;
  display: inline-block;
  margin-left: 2px;
}
.get-link-cp .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em !important;
  color: #000;
  background: transparent url(/cross_button_big.png) center/1.5em auto no-repeat !important;
  border: 0;
  border-radius: 100%;
  font-size: 32px;
  opacity: 15;
}
.get-link-cp .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -4.5rem 1.5rem -0.5rem auto;
}
.get-link-cp .app-icon-ps {
  padding-right: 4px;
}
.get-link-cp .app-icon-aa {
  padding-left: 4px;
}
.get-link-cp input {
  font-size: 36px;
  color: rgb(0, 0, 0);
  letter-spacing: 2.5px;
  line-height: 43px;
  font-weight: 600;
  border: none !important;
}
.get-link-cp .input-group {
  border: 2px solid rgb(12, 11, 12);
  border-radius: 45px !important;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  width: 460px;
  height: 90px;
  font-family: gilroy-bold;
  font-size: 36px;
  color: rgb(0, 0, 0);
  letter-spacing: 2.5px;
  line-height: 29.57px;
  padding: 7px;
  flex-shrink: 0;
}
.get-link-cp span#basic-addon2 {
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
}
.get-link-cp span#basic-addon2 button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin-left: auto;
  background: rgba(12, 11, 12, 0.6);
  cursor: auto;
  border: none !important;
  font-size: 40px !important;
}
.get-link-cp ul {
  padding-left: 1.4rem;
}

.App-modal-cp-mobile .modal-dialog {
  margin: 10rem 0.5rem !important;
}
.fade.App-modal-cp-mobile.modal.show {
  background: #000000b8 !important;
}
.App-modal-cp-mobile .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em !important;
  color: #000;
  background: #928e9a00 url(/cross_button_big.png) center/1.5em auto no-repeat;
  border: 0;
  font-size: 20px;
  border-radius: 0.25rem;
  opacity: 15 !important;
}
.App-modal-cp-mobile .app-icon-ps {
  padding-right: 4px;
}
.btn-close:hover {
  opacity: 15 !important;
}
.App-modal-cp-mobile h2 {
  font-size: 25px !important;
  text-align: center;
  color: #000000 !important;
  text-transform: inherit !important;
}
.App-modal-cp-mobile .app-button-mobile button {
  padding: 10px;
  margin-bottom: 10%;
  border-radius: 20px;
  width: 63%;
  font-size: 20px;
  letter-spacing: 0.05rem;
  font-weight: 600;
  margin-top: 10%;
  background: #0071e3;
  border: 1px solid #0071e3;
}
.App-modal-cp-mobile .app-button-mobile button:hover {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}
.App-modal-cp-mobile .app-button-mobile button:active {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}
.App-modal-cp-mobile .app-button-mobile button:focus {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}
.fade.get-link-cp.modal.show {
  background: #000000e3;
}
.App-modal-cp-mobile .app-button-mobile a {
  font-size: 18px;
  padding: 12px 30px !important;
  border-radius: 50px;
  background: #127fd1;
  border: 1px solid #127fd1;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10%;
  width: 63%;
  letter-spacing: 0.04rem;
  margin-top: 10%;
}
.App-modal-cp-mobile .app-button-mobile a:hover {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}
.App-modal-cp-mobile .app-button-mobile a:active {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}
.App-modal-cp-mobile .app-button-mobile a:focus {
  background: #0071e3;
  border: 1px solid #0071e3 !important;
}

/*  I pad Resolution  */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ug-admissions_ugBigText__f1kYK h1 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .ug-admissions_ugButtonText__XGNkD Button {
    font-size: 2.5vw !important;
    margin-bottom: 55px !important;
  }
  .ug-admissions_ugSmallText__jdxb1 h3 {
    font-size: 2.8vw !important;
    margin-bottom: 45px !important;
  }
  .n-testimonial_AidenSection__s9IME {
    padding-top: 55px !important;
    padding-bottom: 50px !important;
  }
  .header_Login__Ysi34 {
    margin-top: 0px !important;
    width: fit-content !important;
  }
  .n-testimonial_AidenSection__s9IME h3 {
    font-size: 2.8vw !important;
    line-height: 3.5vw !important;
  }
  .team-up-section_teamUpSection__zwWpZ {
    padding-top: 30px !important;
    padding-bottom: 15px !important;
  }
  .leverage_AidenSection__opumi {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .team-up-section_AidenSection__oGy84 h3 {
    font-size: 2.8vw !important;
    line-height: 3.5vw !important;
  }
  .leverage_AidenSection__opumi h3 {
    font-size: 2.8vw !important;
    line-height: 3.5vw !important;
  }
  .scroll-slider_scrollTextToMoveImage__2yXyL {
    padding: 100px 20px 100px !important;
  }
  .scroll-slider_ugMobilSilderSection__fLn9m
    .scroll-slider_ugMobileTextSectionWrap__hKcw5
    .scroll-slider_ugMobilrSilderTextSection__WUgwQ {
    margin-bottom: 435px !important;
  }
  .app-rating_ugAppSection__Yv1QI {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .review_review__Yqs8t p {
    margin-bottom: -30px !important;
    font-size: 2.4vw !important;
    line-height: 3.5vw !important;
  }
  .review_reviewTextSection__dcVL4 {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .review_testimonialForDesktop__2E8gv {
    padding: 0 !important;
  }
  .faq_ugAboutCp__jQY1U {
    padding: 60px 0px 10px 0px !important;
  }
  .faq_ugAboutCp__jQY1U h6 {
    font-size: 3vw !important;
    line-height: 4vw !important;
  }
  .faq_ugAboutCp__jQY1U p {
    font-size: 2.2vw !important;
    letter-spacing: 0.75px !important;
    line-height: 2.8vw !important;
  }
  .footer_ugTopFooter__ChPgA .footer_ugLogosSectionTextTop__sR1bo h6 {
    margin-bottom: 8% !important;
    font-size: 2.5vw !important;
    margin-top: 5% !important;
    line-height: 3vw !important;
  }
  .footer_ugTopFooter__ChPgA .footer_ugLogosSectionTextBottom__3k9cy p {
    font-size: 2.2vw !important;
    letter-spacing: 0.03rem !important;
    line-height: 2.8vw !important;
  }
  .footer_ugTopFooter__ChPgA .footer_ugFooterMenu__S9ALp p {
    font-size: 2.2vw !important;
  }
  .footer_ugTopFooter__ChPgA .footer_ugFooterMenu__S9ALp li {
    font-size: 2vw !important;
    line-height: 2.8vw !important;
  }
  .footer_ugBottomFooter__4Yfgr .footer_ugCopyRight__KazpP p {
    font-size: 2.2vw !important;
  }
  .footer_ugBottomFooter__4Yfgr .footer_ugCopyRightR__nSQnd a {
    font-size: 2.2vw !important;
  }
  .pricing_pricingMainSection__zrgp_.container .pricing_pricing__Or9q6 .pricing_price__16vMC h2 {
    font-size: 22px !important;
    line-height: 22px !important;
  }
  .pricing_selectedPlanBox__60Up1 .pricing_price__16vMC h2 {
    font-size: 25px !important;
    line-height: 25px !important;
  }
  .contact_contactNew__z0Q94 .contact_studentParent__ctAqY .contact_student__EOf3K {
    width: 45% !important;
  }
  .contact_contactNew__z0Q94 .contact_studentParent__ctAqY .contact_parent__uWhK_ {
    width: 45% !important;
  }
  .pricing_selectedPlanBox__60Up1 {
    padding: 25px 8px !important;
    height: 830px !important;
  }
  .pricing_planBoxes__vXAlP {
    padding: 25px 8px !important;
  }
  .video_eventVideoSlider__Xg4qS .video_videoText__KP3un Button {
    margin-bottom: 0;
    margin-top: 160% !important;
  }
  .horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-next:hover {
    height: 70% !important;
    top: 30% !important;
  }
  .horizontalSlider_cardSliderForDesktop__Re9nS .swiper-button-prev {
    height: 70% !important;
    top: 30% !important;
  }
  .series-modal .modal-header .btn-close {
    margin: -25rem 0.5rem 0.5rem auto !important;
  }
  .fade.series-modal.modal.show .card_seriesModalButtonI__SGVYd {
    margin-top: 10% !important;
  }
  .fade.series-modal.modal.show .card_videoTextTrackModal__ScLBZ.row .card_seriesModalButtonI__SGVYd {
    margin-top: 0% !important;
  }
  .card_seriesModalBody__QZeXP.modal-body h5 {
    font-size: 3vw !important;
    line-height: 3.5vw !important;
  }
  .card_seriesModalBody__QZeXP.modal-body p {
    letter-spacing: 0.05rem !important;
    font-size: 2.2vw !important;
  }
  .card_videoTextSeriesModal__pZIX_ button {
    margin-top: -5% !important;
  }
  .card_showImage__v0xTw .card_smallCardPlus__wszQZ {
    top: 20% !important;
    left: 1% !important;
  }
  .card_showImage__v0xTw .card_grade__9jjDx {
    top: 20% !important;
    right: 0 !important;
  }
  .card_showImage__v0xTw {
    margin: 25px 5px 10px 5px !important;
  }
  .card_showImage__v0xTw:hover {
    max-width: 100% !important;
    transform: none !important;
    left: 0% !important;
    top: 0 !important;
    width: 147px !important;
  }
  .card_showImage__v0xTw:hover .card_cardBottomIcon__fe_2L {
    display: none !important;
  }
  .horizontalSlider_cardSliderForDesktop__Re9nS.col .card_showImage__v0xTw:hover img {
    top: 0% !important;
  }
  .swiper {
    padding: 0px 15px 0px 20px !important;
  }
  .card_cardBottomIconShadowEffect__6TuG_ {
    display: none !important;
  }
}

/* Referral Modal  */

.fade.refer-modal.modal.show .modal-dialog {
  max-width: 55%;
  margin: 7rem auto;
}

.css-14el2xx-placeholder {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.04rem !important;
  color: #6c757d !important;
}

.refer_referralFormSection__AazoM .form-floating {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.04rem !important;
  color: #6c757d !important;
}

/* Small Laptop  */

@media only screen and (min-width: 720px) and (max-width: 1280px) {
  .pricing_pricingMainSection__zrgp_.container .pricing_selectedPlanBox__60Up1 .pricing_price__16vMC h2 {
    font-size: 32px !important;
  }
  .pricing_pricingMainSection__zrgp_.container .pricing_pricing__Or9q6 .pricing_price__16vMC h2 {
    font-size: 30px !important;
  }
  .pricing_selectedPlanBox__60Up1 {
    padding: 25px 32px;
    background: #e3cc80;
    color: #000000;
    height: 670px !important;
  }
}

.slider-new-reg {
  padding: 4em 2em;
  margin-bottom: 10px;
}
.slide-track-new-reg {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}
.slide-new-reg img {
  width: 132px;
  height: 132px;
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}
/* referral 2.0 */
/*.referral_bannerRefer__jjHcp.col:after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/cp_hero-triagle-bg.png) bottom 0 left no-repeat;
  background-size: 100% 70px;
  z-index: 1;
}*/
.referral_testimonialCarousel__qABmV.col .carousel-indicators {
  display: none;
}
.referral_testimonialCarousel__qABmV.col a.carousel-control-next {
  width: 2.5% !important;
}
.carousel-inner .card {
  position: relative;
  display: flex;
  background-color: #000000;
  margin: 0px 5px 0px 5px;
}
.cards-wrapper {
  display: flex;
}
.image-wrapper {
  margin: 20;
}

.refer-thank-modal .modal-dialog {
  max-width: 600px !important;
  margin-top: 10%;
}
.refer-thank-modal .modal-header {
  color: #ffffff;
  background: rgb(26, 97, 233);
  display: block;
}
.refer-thank-modal .modal-body {
  background: #ffffff;
  border-radius: 15px;
  border: 10px solid #000000;
}
.fade.refer-thank-modal.modal.show {
  background: #000000d6;
}
.referral_formSection__Dk1tg label.form-label {
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 14px;
  color: #ffffffbf;
}
.referral_plusSectionRef__Cjnuv.row .container {
  margin: 0;
}
.referral_admissionDesc__P_37R li {
  font-size: 17px;
  letter-spacing: 0.05rem;
  margin-bottom: 20px;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
}
.referral_admissionDesc__P_37R ul {
  list-style-type: none;
  padding-left: 0px;
}
.referral_admissionDesc__P_37R li:before {
  position: absolute;
  top: 2px;
  left: -1px;
  font-family: FontAwesome;
  content: '\f067';
  color: #ffffff;
  background: #c83232;
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 50px;
}

.card_seriesModalButtonI__SGVYd.col .btn-primary:disabled {
  color: #000000c4 !important;
  border: 1px solid #ffffff;
}

/*  physical event reg form */
.reg-text p {
  letter-spacing: 0.05rem;
  color: #ffffff;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}
/* Google add form */
.google-form_googleFormSec__5QdYz label.form-label {
  font-size: 13px;
  letter-spacing: 0.05rem;
  font-weight: 600;
  color: #000000fc;
  margin-bottom: 2px;
}
.css-u21oji-control {
  height: 50px;
  border: 1px solid #797a7b !important;
}
div#google-contact .css-i0uack-placeholder {
  color: #000000a6 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  background-color: white;
  font-size: 16px;
  font-weight: 500 !important;
}

.landing-footer-top.row .footer_ugTopFooter__ChPgA.col {
  padding: 35px 0px 10px 0px;
}
.landing-footer-bot.row .footer_ugBottomFooter__4Yfgr.col {
  padding: 25px 0px 20px 0px;
}
.google-form_gradeRedSec__M4URX {
  background-image: linear-gradient(to right, rgb(16 16 16), rgb(142 24 24));
  background-color: #272727;
  border: 1px solid #ffffff40;
  height: auto;
  border-radius: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  font-size: 15px !important;
  font-weight: 500 !important;
  letter-spacing: 0.05rem;
  text-align: center;
}
.lgn-or:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  width: 165px;
  left: 45px;
  height: 1px;
  background: #ffffff30;
}
.lgn-or:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 165px;
  height: 1px;
  background: #ffffff30;
}
.lgn-or {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.75em;
}

/* welcome section */
span.welcome-section a {
  display: flex;
}
.welcome-text {
  margin-top: -2px;
  margin-right: 11px;
}
.welcome-section {
  padding-left: 10px;
}
a.header_dropdown_item__DshnL.dropdown-item:hover a {
  color: #e3cc80 !important;
}

/* New Contact changes */
i.fa.fa-times {
  padding: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
}

.ib-cambridge-tutoring_interestIN__AwxQi.col .css-1n7v3ny-option,
.contact_interestIN__IbyKw.col .css-1n7v3ny-option {
  color: #000000 !important;
}
.ib-cambridge-tutoring_interestIN__AwxQi.col .css-yt9ioa-option,
.contact_interestIN__IbyKw.col .css-yt9ioa-option {
  color: #000000 !important;
}

/* Facebook reg modal */
.facebook-modal.modal .modal-body {
  margin-bottom: 0px;
  margin-top: 0px;
}
.facebook-modal .modal-header {
  background-image: url(/Stanford-fb-form.png);
  background-position: left;
  background-size: cover;
  padding: 160px 5px 65px 5px;
}
.facebook-holo {
  text-align: center;
  margin-top: -40px !important;
}
.facebook-holo img {
  border-radius: 100%;
  background: #000000;
}
.facebook-holo p {
  font-weight: 400;
  letter-spacing: 0.05rem;
  color: #000000d6;
  margin-top: -5px;
  font-size: 14px;
}
.facebook-title-desc {
  text-align: center;
}
.facebook-title-desc h5 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.05rem;
}
.facebook-title-desc p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.03rem;
  line-height: 20px;
  margin-bottom: 5px;
}
.facebook-title-desc a {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.03rem;
  color: #000000;
}
.title-header-section {
  width: 460px;
  max-width: 100%;
  margin: -45px auto 10px auto !important;
  background: #ffffff;
  padding: 15px 30px;
  border-radius: 10px;
}
.student-parent-section {
  width: 460px;
  max-width: 100%;
  margin: 15px auto 15px auto !important;
  background: #ffffff;
  padding: 10px 15px 15px 15px;
  border-radius: 10px;
}
.student-parent-section-grade-sec {
  display: flex;
}
.student-parent-section button {
  letter-spacing: 0.05rem;
  font-weight: 600;
  border-radius: 25px;
  padding: 5px;
  margin: 8px 4px;
  width: 100%;
  font-size: 17px;
  background: #8080804d;
  border: none;
  color: #000000cf;
}
.for-masters-only .student-parent-section button {
  letter-spacing: 0.05rem;
  font-weight: 600;
  border-radius: 25px;
  padding: 5px;
  margin: 8px 4px;
  width: 100%;
  font-size: 17px;
  background: #8080804d;
  border: none;
  color: #000000cf;
}
.for-masters-only .student-parent-section button {
  letter-spacing: 0.05rem;
  font-weight: 600;
  border-radius: 25px;
  padding: 6px 7px;
  margin: 8px 4px;
  width: 100%;
  font-size: 15px;
  background: #8080804d;
  border: none;
  color: #000000cf;
}
.for-masters-only .student-parent-section button:hover {
  background: #c83232;
  color: #ffffff;
}
.student-parent-section button:hover {
  background: #c83232;
}
.student-parent-section p {
  font-weight: 500;
  font-size: 17px;
  color: #000000db;
  letter-spacing: 0.02rem;
}
.student-parent-section .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.facebook-modal .modal-footer {
  background: #ffffff;
  justify-content: center !important;
}
.facebook-modal .modal-footer button {
  background: #c83232;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.05rem;
  width: 100%;
  border: none;
}
.facebook-form-privacy {
  width: 460px;
  max-width: 100%;
  margin: -50px auto 150px auto !important;
  background: #ffffff;
  padding: 30px 20px 40px 20px;
  border-radius: 10px;
}
.facebook-form-privacy h5 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03rem;
}
.facebook-form-privacy p {
  font-size: 15px;
  letter-spacing: 0.03rem;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: 10px;
}
.facebook-form-contact-info {
  width: 460px;
  max-width: 100%;
  margin: -50px auto 10px auto !important;
  background: #ffffff;
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
}
.facebook-form-contact-info h5 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03rem;
}
.facebook-form-contact-info p {
  font-size: 17px;
  letter-spacing: 0.03rem;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: 30px;
}
.facebook-form-contact-info h5 i {
  padding-left: 10px;
}
.facebook-form-contact-info .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.facebook-modal .modal-header button.btn-close {
  margin-right: 0px;
  margin-top: -290px;
}
.facebook-modal {
  background: #f2f2f2;
}
div#standard-basic {
  background: none;
}
.facebook-modal .modal-content {
  border: none;
  background-color: #80808030;
}
.student-parent-section-btn {
  display: flex;
}

#react-autowhatever-1 {
  background-color: white !important;
  color: #000000 !important;
}
.css-1d1r5q-MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.03rem;
  margin-top: 5px;
}
p.MuiFormHelperText-root.Mui-error.css-j7o63n {
  color: #d32f2f;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.03rem;
  margin-top: 5px;
}
i.fa.fa-arrow-left.facebook-modal-back-btn {
  border-radius: 100%;
  position: absolute;
  left: 10px;
  top: 10px;
  color: #ffffff;
  background: #0000004a;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.team-up-section_advisorSection__jrCv_.col .carousel-inner {
  border: 5px solid #ffffff !important;
}

@media only screen and (max-width: 1440px) and (min-width: 900px) {
  .team-up-section_teamUpSection__zwWpZ .team-up-section_advisorSection__jrCv_ {
    padding: 5% 5% 0% 12% !important;
  }
}

/* Phone */

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .registration_swiperNew__beprs .swiper {
    padding: 60px 30px 60px 30px !important;
    /* overflow: visible !important; */
  }
  .google-form_lpdeskHederBannerLb__Zs2__.col .row .col {
    margin-left: 0px;
  }
  .google-form_lpdeskHederBannerLb__Zs2__.col .row {
    width: auto;
  }
  .google-form_googleFormSec__5QdYz label.form-label {
    font-size: 14px;
  }
  .facebook-modal .modal-header {
    background-image: url(/Stanford-fb-form.png);
    background-position: left;
    background-size: cover;
    padding: 115px 5px 70px 5px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
  .facebook-modal .modal-footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;
    justify-content: center !important;
  }
  .facebook-modal.modal .modal-body {
    margin-bottom: 55px;
    margin-top: 125px;
  }
  .facebook-modal .modal-content {
    border: none;
    background-color: #fff0;
  }
  .facebook-modal .modal-header {
    background-image: url(/Stanford-fb-form.png);
    background-position: left;
    background-size: cover;
    padding: 115px 5px 70px 5px;
  }
  .title-header-section {
    width: 460px;
    max-width: 100%;
    margin: 3px auto 10px auto !important;
    background: #ffffff;
    padding: 15px 30px;
    border-radius: 10px;
  }
  .facebook-modal .modal-header button.btn-close {
    margin-right: 0px;
    margin-top: -200px;
  }
  .facebook-form-contact-info {
    width: 460px;
    max-width: 100%;
    margin: 15px auto 10px auto !important;
    background: #ffffff;
    padding: 30px 30px;
    border-radius: 10px;
  }
  .facebook-form-privacy {
    width: 460px;
    max-width: 100%;
    margin: 15px auto 150px auto !important;
    background: #ffffff;
    padding: 30px 20px 40px 20px;
    border-radius: 10px;
  }
  i.fa.fa-arrow-left.facebook-modal-back-btn {
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    color: #ffffff;
    background: #0000004a;
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
}
.faq_accoVer3__J1fE9 .accordion-header {
  background: #222326;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.faq_accoVer3__9jELl.row .accordion-header,
.faq_accoVer3__9jELl .accordion-header {
  background: #222326;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.faq_accoVer3__J1fE9 button.accordion-button,
.faq_accoVer3__gDdDw .accordion-header {
  color: #ffffff;
  background: #222326;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.05rem;
}
.faq_accoVer3__9jELl.row button.accordion-button,
.faq_accoVer3__gDdDw button.accordion-button {
  background: #222326;
  color: #ffffff;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.05rem;
}
.faq_accoVer3__J1fE9 .accordion-body {
  background: #222326;
  color: #ffffff;
  border-radius: 5px;
  letter-spacing: 0.05rem;
  line-height: 25px;
  padding: 15px 20px;
  font-weight: 300;
}
.faq_accoVer3__9jELl.row .accordion-body,
.faq_accoVer3__9jELl button.accordion-button {
  background: #222326;
  color: #ffffff;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.05rem;
}
.faq_accoVer3__gDdDw button.accordion-button {
  background: #222326;
  color: #ffffff;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.05rem;
}
.faq_accoVer3__9jELl .accordion-body {
  background: #222326;
  color: #ffffff;
  border-radius: 5px;
  letter-spacing: 0.05rem;
  line-height: 25px;
  padding: 25px 20px;
  font-weight: 300;
}
.faq_accoVer3__gDdDw .accordion-body {
  background: #222326;
  color: #ffffff;
  border-radius: 5px;
  letter-spacing: 0.05rem;
  line-height: 25px;
  padding: 25px 20px;
  font-weight: 300;
}
.faq_accoVer3__9jELl .accordion-item {
  border: none;
  background-color: #222326;
  margin: 20px 0px;
  border-radius: 5px;
}
.faq_accoVer3__gDdDw .accordion-item {
  border: none;
  background-color: #222326;
  margin: 20px 0px;
  border-radius: 5px;
}
.faq_accoVer3__J1fE9.row .accordion-item {
  border: none;
  background-color: #222326;
  margin: 20px 0px;
  border-radius: 5px;
}
.accordion-button:after,
.accordion-button:not(.collapsed):after {
  background-image: url(/download-drop-v3.png) !important;
  background-size: 0.8rem !important;
}
.review_reviewTestimonialCards__TD3tu.col .row {
  /* border-left: 1px solid #ffffff1f; */
  /* border-right: 1px solid #ffffff1f; */
  padding: 0px 2px;
}
.team-up-section_advisorSection__jrCv_.col a.carousel-control-prev {
  display: none;
}
.team-up-section_advisorSection__jrCv_.col a.carousel-control-next {
  display: none;
}
.team-up-section_advisorSection__jrCv_.col .carousel-indicators {
  display: none;
}
.team-up-section-lp_advisorSection__XtCtF.col a.carousel-control-next {
  display: none;
}
.team-up-section-lp_advisorSection__XtCtF.col .carousel-indicators {
  display: none;
}

/* blog 2024  */

.post-link-img img {
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 700px;
  max-width: 100%;
}
.post_postDescText__FuoJz li {
  margin-bottom: 15px;
  letter-spacing: 0.02rem;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.875rem;
  word-spacing: 5px;
}
.banner-html-template .img-section-link {
  text-align: center;
}
.banner-html-template .img-section-link img {
  max-width: 100%;
  width: 500px;
}
.text-section {
  padding-top: 35px;
}
.banner-html-template {
  background-color: #ffe2d5;
  border: 1px solid #ff9f9f;
  padding: 10px;
  margin: 10px 0px;
}
.btn-link-banner button {
  margin: 20px 0px;
  font-size: 22px;
  font-weight: bold !important;
  text-align: center !important;
  text-decoration: none !important;
  font-family: sans-serif !important;
  border-radius: 8px !important;
  background-color: rgb(214, 26, 26) !important;
  background-image: -webkit-linear-gradient(top, rgb(214, 26, 26), rgb(160, 19, 19)) !important;
  background-image: -moz-linear-gradient(top, rgb(214, 26, 26), rgb(160, 19, 19)) !important;
  box-shadow: inset 0px 1px rgb(255, 32, 32) !important;
  -webkit-box-shadow: inset 0px 1px rgb(255, 32, 32) !important;
  -moz-box-shadow: inset 0px 1px rgb(255, 32, 32) !important;
  color: rgb(255, 255, 255) !important;
  border: 2px solid rgb(107, 13, 13) !important;
  border-bottom: 8px solid rgb(107, 13, 13) !important;
  text-shadow: 0px -1px rgb(64, 7, 7) !important;
  position: relative !important;
  line-height: 1.5em !important;
  padding: 22px 45px !important;
}
.icon-img-autor img {
  border-radius: 100%;
}
.blog_headerBannerMob__xdeIA.row {
  margin-right: 8px;
}

p.wp-icon:hover {
  color: #ffffff;
  background: #25d366;
  box-shadow: inset 0 0 0 2px #e4e7f1;
}
p.fb-icon:hover {
  background: #17a9fd;
  color: #ffffff;
  box-shadow: inset 0 0 0 2px #e4e7f1;
}
p.li-icon:hover {
  color: #ffffff;
  background: #0a66c2;
  box-shadow: inset 0 0 0 2px #e4e7f1;
}
svg:hover {
  color: #ffffff !important;
  /* background: black; */
  /* box-shadow: inset 0 0 0 2px #e4e7f1 !important; */
}

/* New landing page for Add */

.google-add_addLpBg__Rp7O8 .carousel-indicators {
  margin-bottom: -2rem;
}
.google-add_addLpBg__Rp7O8 .carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.google-add_addLpBg__Rp7O8 .carousel-control-next {
  top: 104%;
}
.google-add_addLpBg__Rp7O8 .carousel-control-prev {
  top: 104%;
}
.google-add_addLpBg__Rp7O8 .carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}
.google-add_addLpBg__Rp7O8 .carousel-control-prev {
  left: 85%;
  width: 5.5% !important;
}
.google-add_addLpAccordian__vRvaa.row .accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.google-add_addLpAccordian__vRvaa.row .accordion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;
  border-left: none;
}
.google-add_addLpAccordian__vRvaa.row .accordion-button::after {
  background-image: url(/download-drop-white.png) !important;
}

/* logo slider */

.logo-slider img {
  width: 80px;
  height: 80px;
}
.animate {
  animation: scroll 20s linear infinite;
  text-align: center;
  width: 130px;
  max-width: 100%;
}
.animate p {
  margin-bottom: 0;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 600;
  /* width: max-content; */
}
.logo-slider .slide-track {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}

.logo-slider .slider {
  padding: 1em 0em;
  background: #e8e8e8;
}
.logo-slider .slider h2 {
  color: rgb(0, 0, 0);
  padding-bottom: 20px;
  text-align: center;
  font-size: 25px !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}

.google-add_formTextAdd__GM0_R .PhoneInput {
  display: flex;
  align-items: center;
  background: #ffffff !important;
  padding: 6px 15px !important;
  border-radius: 4px !important;
  border: none;
}
.google-add_formTextAdd__GM0_R .PhoneInputInput {
  outline: 0;
  border: none;
}

/* Testimonial carosel new */
/* 
.swiper {
	padding:5px;
}
	.swiper .contentWrapper {
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: ".";
		width: 100%;
		justify-items: center;
		align-items: center;
  }
	.swiper .contentWrapper	.content {
			padding: 10px 2px;
      text-align: center;
      display: grid;
      justify-items: center;
      align-items: center;
      margin:0 auto;
			color:#000000;
			:first-child {
				margin: 0;
			}
    }
  .swiperAvatar img {
    border-radius: 500px;
  }
  .swiperAvatar {
    width:100%;
    max-width:125px;
    height:auto;
  }
  .cite {
    font-size:14px;
    font-weight:bold;
  }
.swiperSlide {
  margin: 0;
  height: auto;
  width: 100%;
  padding:0;
  opacity:.2;
  background:rgba(255,255,255,.3);
  border-radius:6px;
  transition: all .5s ease-in-out;
}
  .swiperSlide-active {
    background:rgba(255,255,255,1);
    opacity:1;
    transform: scale(1.1);
  }
.swiper-nav-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: auto;
  padding-top: 2px 10px;
}
.swiper-button-next,
		.swiper-button-prev {
			top: 0;
			top: auto;
			left: auto;
			right: auto;
			position: relative !important;
			&:after {
				display: none;
			}
		}
.swiper-button-next,
		.swiper-container-rtl .swiper-button-prev {
			background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
			width: 20px;
			height: 20px;
			background-size: 20px 20px;
			background-repeat: no-repeat;
			margin: 0;
			padding: 0;
		}
.swiper-button-prev,
		.swiper-container-rtl .swiper-button-next {
			background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='16px' viewBox='0 0 9 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-right' transform='translate(4.508789, 7.870605) rotate(-180.000000) translate(-4.508789, -7.870605) translate(-0.000000, -0.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M8.674805,7.066406 L1.924805,0.316406 C1.696288,0.105468 1.432619,0 1.133789,0 C0.834959,0 0.57129,0.105468 0.342773,0.316406 C0.114257,0.544923 0,0.808592 0,1.107422 C0,1.406251 0.114257,1.669921 0.342773,1.898438 L6.301758,7.857422 L0.342773,13.816406 C0.114257,14.044923 0,14.308592 0,14.607422 C0,14.906251 0.114257,15.169921 0.342773,15.398438 C0.465821,15.521485 0.584472,15.609375 0.69873,15.662109 C0.812989,15.714844 0.958007,15.741211 1.133789,15.741211 C1.309571,15.741211 1.454589,15.714844 1.568848,15.662109 C1.683106,15.609375 1.801757,15.521485 1.924805,15.398438 L8.674805,8.648438 C8.903321,8.419921 9.017578,8.156251 9.017578,7.857422 C9.017578,7.558592 8.903321,7.294923 8.674805,7.066406 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
			width: 20px;
			height: 20px;
			background-size: 20px 20px;
			background-repeat: no-repeat;
			margin: 0;
			padding: 0;
		}

.swiper-pagination {
	margin: 0;
	padding: 0;
	width: auto;
	position: relative !important;
	display: block;
	width: auto;
}
.swiper-pagination-bullets {
  margin: 0;
}
.swiper-pagination-bullet {
  margin: 0;
  background: #fff !important;
}
.swiper-pagination-bullet {
	background: #fff !important;
	transition: all .2s ease-in-out;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	bottom: 0;
	top: 0;
	bottom: 0;
	width: auto;
	padding: 0 10px;
}
.swiper-pagination-bullet-active {
    transform: scale(1.5);
} */

.swiper-slide {
  margin: 0;
  max-height: 100%;
  padding: 0;
  border: none;
  color: #ffffff;
  opacity: 1;
  background: rgb(0 0 0 / 72%);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}
.registration_swiperNew__beprs .swiper-slide {
  margin: 0;
  height: 360px !important;
  max-height: 100%;
  padding: 0;
  border: 1px solid rgb(255 255 255 / 46%) !important;
  color: #ffffff;
  opacity: 1;
  background: rgb(0 0 0 / 72%);
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
}
.registration_swiperNew__beprs .swiper-slide.swiper-slide-active {
  background: rgb(0 0 0);
  opacity: 1;
  transform: scale(1.21);
}
.content.swiper-avatar {
  width: 100%;
  max-width: 125px;
  height: auto;
}
.content.swiper-avatar img {
  border-radius: 500px;
}
p.cite {
  position: absolute;
  bottom: -13%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.registration_swiperNew__beprs .swiper-button-prev {
  left: -15px !important;
  right: auto;
}
.registration_swiperNew__beprs .swiper-button-next {
  right: -15px !important;
  left: auto;
}
.registration_swiperNew__beprs .swiper-pagination-bullet {
  background: #ffffff !important;
}
.registration_swiperNew__beprs .swiper-pagination-bullet {
  height: 8px !important;
  width: 8px !important;
}
.registration_swiperNew__beprs span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #fff;
  opacity: 1;
  transition: all 0.2s ease-in;
  height: 8px !important;
  width: 8px !important;
  border-radius: 15px;
  background-color: #fff !important;
}
.registration_swiperNew__beprs .swiper-avatar-img img {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.swiper-avatar-img {
  padding-top: 20px;
}
/*--------------------------------------*/
/* bg dark for landing */
.list-wrapper.founder-circle {
  max-width: 63rem;
}
.padding-global {
  padding-left: 5%;
  padding-right: 5%;
}
.container-large {
  padding-top: 7rem;
  max-width: 70rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.list-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.list1_component {
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}
#w-node-bg-dark {
  align-self: start;
}
.list1_item {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.layout65_item-icon-wrapper {
  flex: none;
  align-self: center;
  margin-right: 1rem;
}
.icon-embed-xsmall {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  max-height: 1.5rem;
  display: flex;
  color: #ffffff;
}
.layout65_item-text-wrapper {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.inherit {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
}

/* New Login */
.new-signin-txtbx .form-control {
  display: block;
  width: 100%;
  padding: 15px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #5f5e5e;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.new-signin-txtbx .form-control:focus {
  color: #ffffff;
  background-color: #ffffff00;
}
form.new-signin-form .new-signin-btnsbmt {
  background: #e32552;
  border-radius: 0.25rem;
  border: none;
  padding: 10px;
  color: #ffffff;
  letter-spacing: 0.03rem;
}
form.new-signin-form .new-signin-frgt {
  letter-spacing: 0.03rem;
  background: rgba(128, 128, 128, 0.4);
  border: none;
  padding: 10px;
  color: #ffffff;
}

.scl-lgo-sldr-dsk {
  display: block;
}
.scl-lgo-sldr-mbl {
  display: none;
}
.wrapper-marquee {
  padding: 15px 0px;
  max-width: 100%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 50s linear infinite;
}
.marquee img {
  width: 80px;
}
.marquee p {
  display: inline-block;
  color: #000000;
  font-size: 17px;
  letter-spacing: 0.03rem;
  margin: 0px 20px;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.custom-testimonial-padding {
  padding-left: 80px !important;
}

/* Card modal new update april - 2025 */

.modal-title.h4 img {
  object-fit: cover;
  object-position: bottom;
}
